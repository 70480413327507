import { NotificationType } from "./NotificationType";

/**
 * A notification message that can be displayed to the user.
 */
export class NotificationMessage {
	/** A unique automatically generated id */
	public id: number;

	/** The type of notification to display */
	public type: NotificationType;

	/** An optional title to display in the message */
	public title?: string | null;

	/** The main notification message to display */
	public message: string;

	/** The amount of milliseconds to display the message for, a value of 0 means that the message will not close automatically */
	public timeout: number;

	constructor(instance: NotificationMessage) {
		this.id = instance.id;
		this.type = instance.type;
		this.title = instance.title;
		this.message = instance.message;
		this.timeout = instance.timeout;
	}
}
