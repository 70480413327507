import React, { memo, useEffect, useState } from "react";
import { ClaimForm } from "./ClaimForm/ClaimForm";
import { MainCategory } from "../api/ExternalApi";
import ClaimsHeader from "./ClaimForm/Marginals/ClaimsHeader";
import { Claim, IFieldProps, initialValues } from "../models/IClaimFormDefaults";
import { newClaimsClient } from "../api/newClaimsClient";
import { FormValidation } from "../validation/FormValidation";
import { Container } from "react-bootstrap";
import { VersionStyle } from "../styles/styledComponents/styled";
import { Grid } from "@material-ui/core";
import BuildVersion from "../build-version.json";
import Footer from "./ClaimForm/Marginals/Footer";
import { IntlProvider } from "react-intl";
import { localeLanguage, messagesForLanguages } from "../Utils/translations/locales";
import NotificationsManager from "./ErrorHandling/Notifications/NotificationsManager";
import { removeEmpty } from "../Utils/Utils";

interface IProps {
	insuranceId: string;
}

const ClaimFormContainer = (props: IProps) => {
	const [claim, setClaim] = useState(initialValues as Claim);
	const [claimKey, setClaimKey] = useState({
		marketName: "",
		platformId: "",
		jiraIssueKey: "",
		platformDisplayName: "",
		insuranceStart: undefined,
		insuranceEnd: undefined
	} as IFieldProps);
	const [savedItems, setSavedItems] = useState([] as any);
	const [insuranceIdError, setInsuranceIdError] = useState(false);
	const [marketCurrency, setMarketCurrency] = useState("");
	const [pincode, setPincode] = useState("");
	const [showPincode, setShowPincode] = useState(false);
	const [mainCategory, setMainCategory] = useState("" as any);
	const [allowTypeChange, setAllowTypeChange] = useState(true);
	const [locale, setLocale] = React.useState(localeLanguage);

	const messages = { ...messagesForLanguages.en, ...messagesForLanguages[locale] };

	useEffect(() => {
		let search = new URLSearchParams(window.location.search);
		let insuranceIdParameter = FormValidation().isValidGuid(props.insuranceId) ? props.insuranceId : search.get("insuranceId");
		let pincodeParameter = search.get("pincode");
		
		let userHandleParameter = search.get("userHandle");
		let userHandleGuid = FormValidation().isValidGuid(userHandleParameter || "") ? userHandleParameter : ""; 
		
		if (insuranceIdParameter && FormValidation().isValidGuid(insuranceIdParameter)) {
			populateClaim(insuranceIdParameter, pincodeParameter || "", userHandleGuid || "");
		} // eslint-disable-next-line
	}, []);

	const populateClaim = async (insuranceId: string, pincode?: string, userHandle?: string) => {
		try {
			const newRequest = newClaimsClient();
			const result = await newRequest.claimsClient.formDefaults(
				newRequest.platformType,
				insuranceId.toString(),
				pincode,
				userHandle
			) as any;
			if (result) {
				setShowPincode(result.claimFormRequiresPincode);
				setMarketCurrency(result.marketCurrency || "");
				setMainCategory(result.mainCategory);
				setAllowTypeChange(false);
				setClaimKey({
					marketName: result.marketHandle.marketName || "",
					platformId: result.marketHandle.platformId || "",
					jiraIssueKey: result.jiraIssueKey || "",
					platformDisplayName: result.platformDisplayName || "",
					insuranceStart: result.inuranceStart || undefined,
					insuranceEnd: result.insuranceEnd || undefined
				});
				if (result.claim?.item) {
					if (result.claim?.item.length === 1) {
						result.claim.item = result.claim.item[0];
					} else if (result.claim?.item.length > 1) {
						let resultItem = result.claim.item[0];
						result.claim.item.shift();
						setSavedItems(savedItems.length ? savedItems.concat(result.claim.item) : result.claim.item);
						result.claim.item = resultItem;
					}
				}

				let trimmedResult = removeEmpty(result.claim) as any;
				setClaim({
					...claim,
					...trimmedResult,
					item: {
						...claim.item,
						...trimmedResult?.item
					},
					vehicle: {
						...claim.vehicle,
						...trimmedResult?.vehicle
					},
					accommodation: {
						...claim.accommodation,
						...trimmedResult?.accommodation
					}
				} as Claim);
				setInsuranceIdError(false);

			}
			else {
				setInsuranceIdError(true);
				NotificationsManager.error(
					messages["ClaimForm.populateFromInsuranceId"]
				);
			}
		} catch (ex: any) {
			setInsuranceIdError(true);
			if (ex?.status === 404) {
				NotificationsManager.error(
					messages["ClaimForm.populateFromInsuranceId"]
				);
			} else {
				NotificationsManager.error(ex?.detail ?? messages["ClaimForm.InstanceOfError"])
			}
		}
	}

	const setClaimFromHeader = (claim: Claim, category: string) => {
		setMainCategory(category);
		setClaim(claim);
	}

	const stringToMainCategory = (category: string) => {
		switch (category) {
			case "vehicle":
				return MainCategory.Vehicle;
			case "accommodation":
				return MainCategory.Accommodation;
			case "item":
				return MainCategory.Item;
			default:
				return undefined;
		}
	}

	const setCategory = (category: string) => {
		if(category === "") {
			setClaim(
				claim => ({
					...claim,
					item: {"objectPriceCurrency": ""},
					vehicle: {},
					accommodation: {}
				}) as Claim
			)
		}
		setMainCategory(stringToMainCategory(category.toLocaleLowerCase()));
	}

	const clearCategory = () => {
		setClaim(
			claim => ({
				...claim,
				item: {
					"objectPriceCurrency": marketCurrency !== "" ? marketCurrency : "SEK"
				}
			} as Claim)
		)
	}

	const handleFieldChange = (event: any, id: string, category: string) => {
		let content = event;

		if (event && event.currentTarget) {
			content = event.currentTarget.value;
		}

		if (category) {
			setClaim(
				claim => ({
					...claim,
					[category]:
					{
						...claim[category],
						[id]: content
					}
				} as Claim)
			)
		}

		else {
			setClaim(
				claim => ({
					...claim,
					[id]: content
				} as Claim)
			)
		}

		if (id === "insuranceId") {
			setInsuranceId(content, pincode);
		}
	};

	const handleSetPincode = (event: any) => {
		if (event.currentTarget) {
			event = event.currentTarget.value;
		}
		setPincode(event);
		if (event.length === 5) {
			setInsuranceId(claim.insuranceId, event);
		}
	}

	const setInsuranceId = (insuranceId: any, pincode?: string) => {
		if (insuranceId && FormValidation().isValidGuid(insuranceId)) {
			populateClaim(insuranceId, pincode);
		}
		else {
			return;
		}
	}

	useEffect(() => {
		const url = window.location.pathname;
		const param = url.split("/");
		const category = param[2] === "accomodation" ? "accommodation" : param[2];
		if (category) {
			setMainCategory(stringToMainCategory(category.toLocaleLowerCase()));
			setAllowTypeChange(false);
		}
	}, []);

	return (
		<IntlProvider key={locale} locale={locale} defaultLocale={"en"} messages={messages}>
			<Container fluid>
				<Grid container className="justify-content-center">
					<Grid item lg={8}>
						<div id="maincontainer">
							<div id="formholder">
								<ClaimsHeader claimState={claimKey} setClaim={setClaimFromHeader} locale={locale} setLocale={(value: string) => setLocale(value)} />
								<ClaimForm
									claim={claim}
									setValue={handleFieldChange}
									allowTypeChange={allowTypeChange}
									setAllowTypeChange={(typeChange: boolean) => setAllowTypeChange(typeChange)}
									savedItems={savedItems}
									setSavedItems={setSavedItems}
									pincode={pincode}
									setPincode={(event: any) => handleSetPincode(event)}
									claimState={claimKey}
									marketCurrency={marketCurrency}
									category={mainCategory}
									setCategory={(category: string) => setCategory(category)}
									clearCategory={() => clearCategory()}
									showPincode={showPincode}
									insuranceIdError={insuranceIdError}
								/>
							</div>
						</div>
					</Grid>
				</Grid>
			</Container>
			<Footer />
			<VersionStyle>
				Build Version: {BuildVersion.BuildVersion}
			</VersionStyle>
		</IntlProvider >
	);
};
export default memo(ClaimFormContainer);
