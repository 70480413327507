import React from "react";
import moment from "moment";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CloseIcon from '@material-ui/icons/Close';

interface IBasket {
    savedItem: any;
    index: number;
    removeItem: Function;
    done: boolean;
}

const Basket = (props: IBasket) => {
    const { savedItem, index, removeItem, done } = props;

    const addClass = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        let classList = e.currentTarget?.parentElement?.parentElement?.classList || e.currentTarget.classList;
        classList.contains("basket-expand") ? classList.remove("basket-expand") : classList.add("basket-expand");
    }

    return (
        <div className="basket">
            <div className="basket-header-wrapper">
                <div className="basket-header" onClick={e => addClass(e)}>
                    <span className="basket-expand"><RemoveIcon /></span>
                    <span className="basket-shrink"><AddIcon /></span>
                    <span className="header-value">{savedItem.objectDescription}</span>
                </div>
                {!done && <span className="basket-close" onClick={() => removeItem(index)}><CloseIcon /></span>}
            </div>
            <div className="basket-items">
                <div>
                    <div className="basket-label">Date of purchase</div>
                    <div className="basket-value">{moment(savedItem.dateOfPurchase).format('Do MMMM YYYY')}</div>
                </div>
                <div>
                    <div className="basket-label">Place of purchase</div>
                    <div className="basket-value">{savedItem.placeOfPurchase}</div>
                </div>
                <div>
                    <div className="basket-label">Was the item new?</div>
                    <div className="basket-value">{savedItem.objectNewAtPurchase ? "Yes" : "No"}</div>
                </div>
                <div>
                    <div className="basket-label">Item price</div>
                    <div className="basket-value">{`${savedItem.objectPriceAmount} ${savedItem.objectPriceCurrency || "SEK"}`}</div>
                </div>
            </div>
        </div>
    )
};

export default Basket;
