import React, { FC, useEffect } from "react";
import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@material-ui/core";
import * as serviceWorker from "./serviceWorker";

const ServiceWorkerWrapper: FC = () => {
	const [showReload, setShowReload] = React.useState(false);
	const [
		waitingWorker,
		setWaitingWorker,
	] = React.useState<ServiceWorker | null>(null);

	const onServiceWorkerUpdate = (registration: ServiceWorkerRegistration) => {
		setWaitingWorker(registration.waiting);
		setShowReload(true);
	};

	useEffect(() => {
		serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
	}, []);

	const reloadPage = () => {
		if (waitingWorker) {
			waitingWorker.postMessage({ type: "SKIP_WAITING" });
		}
		setShowReload(false);
		window.location.reload();
	};

	return (
		<Dialog
			open={showReload}
			onClose={(_event, reason) => {
				if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
					setShowReload(false);
				}
			}}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			disableEscapeKeyDown
		>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					A new version is available. Please reload the page to get an
					updated version.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={reloadPage} color="primary">
					Reload
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ServiceWorkerWrapper;
