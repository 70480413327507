import React, { useState } from "react";
import { InputLabel, FormGroup, FormControl, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useIntl } from "react-intl";
import { useItemInputStyles } from "../../../../styles/styledComponents/stylesForComponents";
import { FieldGroupString } from "../../CommonComponents/FieldGroupString";
import Dropzone from "../Dropzone/Dropzone";
import { handleReceiptDropped } from "../Dropzone/handleDropzone";
import Basket from "../Basket";
import ButtonComponent from "../../CommonComponents/ButtonComponent";
import DateComponent from "../../CommonComponents/DateComponent";
import CurrencyComponent from "../../CommonComponents/CurrencyComponent";
import { MainCategory } from "../../../../api/ExternalApi";
import { Claim, FieldType } from "../../../../models/IClaimFormDefaults";
import NotificationsManager from "../../../ErrorHandling/Notifications/NotificationsManager";

interface IItemInputInformationProps {
	claim: Claim;
	setValue: Function;
	done: boolean;
	saveItem: Function;
	setCategory: Function;
	marketCurrency: string;
	allowTypeChange?: boolean;
	savedItems: [];
	setSavedItems: Function;
	files: [File, string][];
	setFiles: Function;
	removeFile: Function
	setError: Function;
	displayErrors: boolean;
}

const ItemInputInformation: React.FC<IItemInputInformationProps> = (props) => {
	/** Initialization */
	const { claim, setValue, done, saveItem, setCategory, marketCurrency, allowTypeChange, savedItems, setSavedItems, files, setFiles, removeFile, setError, displayErrors } = props;
	const classes = useItemInputStyles();
	const intl = useIntl();
	const [fileLoading, setFileLoading] = useState(false);

	const removeItem = (index: number) => {
		let tempSavedItems = [...savedItems];
		tempSavedItems.splice(index, 1);
		setSavedItems(tempSavedItems);
		
		NotificationsManager.info(
			intl.formatMessage({
				id: "ClaimForm.ItemRemoved",
			})
		);
	}

	const insertItems = () => {
		let items = [];
		for (let i = 0; i < savedItems.length; i++) {
			items.push(<Basket savedItem={savedItems[i]} index={i} removeItem={removeItem} done={done} key={"basket-" + i} />);
		}
		return items;
	}

	const dropzoneForReceipt = {
		acceptValue: intl.formatMessage({
			id: "ClaimForm.isDragAcceptforReceipt",
		}),
		rejectValue: intl.formatMessage({
			id: "ClaimForm.isDragRejectforReceipt",
		}),
		activeValue: intl.formatMessage({
			id: "ClaimForm.isDragInActiveforReceipt",
		}),
		thumbs: intl.formatMessage({
			id: "ClaimForm.receiptsThumbs",
		}),
		allowedFormats: intl.formatMessage({
			id: "ClaimForm.AllowedFileFormatOfReceipt",
		}),
		resizingImage: intl.formatMessage({
			id: "ClaimForm.ResizingImage",
		})
	};

	// DropZone
	const onDrop = async (acceptedFiles: File[]) => {
		setFileLoading(true);
		let tempFiles = await handleReceiptDropped(acceptedFiles, files, intl);
		setFileLoading(false);
		tempFiles && setFiles(tempFiles);
	}

	return (
		<>
			<Grid container spacing={3}>
				<Grid item md={7} xs={12}>
					{allowTypeChange && <input type="button" className="text-button padding"
						onClick={() => setCategory("")} value={intl.formatMessage({
							id: "ClaimForm.ReselectInsuranceType",
						})} />}
					<FieldGroupString
						value={claim.item?.objectDescription || ""}
						onChange={setValue}
						done={done}
						multiLineTextBox
						id="objectDescription"
						name="objectDescription"
						fieldType={FieldType.Text}
						category="item"
						label={intl.formatMessage({
							id: "ClaimForm.objectDescriptionLabel",
						}) + " *"}
						isRequired
						placeholder={intl.formatMessage({
							id: "ClaimForm.objectDescriptionSmallText",
						})}
						setError={setError}
						displayErrors={displayErrors}
					/>

					<DateComponent
						value={claim.item?.dateOfPurchase}
						onChange={setValue}
						done={done}
						id="dateOfPurchase"
						fieldName="dateOfPurchase"
						category="item"
						label={intl.formatMessage({
							id: "ClaimForm.dateOfPurchaseLabel",
						}) + " *"}
						placeholder={intl.formatMessage({
							id: "ClaimForm.dateOfPurchasePlaceholder",
						})}
						flatPickerOptions={{
							allowInput: true,
							minDate: "1990-01-01"
						}}
						isRequired
						displayErrors={displayErrors}
					/>

					<FieldGroupString
						value={claim.item?.placeOfPurchase || ""}
						onChange={setValue}
						done={done}
						multiLineTextBox
						id="placeOfPurchase"
						name="placeOfPurchase"
						fieldType={FieldType.Text}
						category="item"
						label={intl.formatMessage({
							id: "ClaimForm.placeOfPurchaseLabel",
						}) + " *"}
						placeholder={intl.formatMessage({
							id: "ClaimForm.placeOfPurchasePlaceholder",
						})}
						isRequired
						setError={setError}
						displayErrors={displayErrors}
					/>

					<FormGroup id="formControlsCondition">
						<ButtonComponent
							value={claim.item?.objectNewAtPurchase}
							onChange={setValue}
							category="item"
							done={done}
							fieldName="objectNewAtPurchase"
							label={intl.formatMessage({
								id: "ClaimForm.objectNewAtPurchase",
							}) + " *"}
						/>
						{claim.item?.objectNewAtPurchase === undefined &&
							displayErrors ? (
							<span className="buttonStateError">
								{intl.formatMessage({
									id: "ClaimForm.validateFieldRequired",
								})}
							</span>
						) : (
							<span />
						)}
					</FormGroup>
					<Grid container className="objectPrice">
						<InputLabel>
							{intl.formatMessage({
								id: "ClaimForm.objectPriceLabel",
							}) + " *"}
						</InputLabel>
						<Grid item md={8} xs={8} className="objectPriceAmount">
							<FieldGroupString
								value={claim.item?.objectPriceAmount || ""}
								onChange={setValue}
								done={done}
								multiLineTextBox
								id="objectPriceAmount"
								name="objectPriceAmount"
								fieldType={FieldType.Number}
								category="item"
								placeholder={intl.formatMessage({
									id: "ClaimForm.objectPrice",
								})}
								isRequired
								setError={setError}
								displayErrors={displayErrors}
							/>
						</Grid>
						<Grid item md={4} xs={4} className="objectPriceCurrency">
							<FormControl className={classes.margin} fullWidth>
								<CurrencyComponent
									claim={claim}
									marketCurrency={marketCurrency}
									onChange={setValue}
								/>
							</FormControl>
						</Grid>
						<Grid item md={10} xs={12}>
							<Button
								disabled={done}
								name="AddNewItem"
								variant="contained"
								onKeyPress={(e) => {
									(e.key === "Enter" || e.key === " ") && saveItem(MainCategory.Item);
								}}
								onClick={(
									e: React.MouseEvent<HTMLButtonElement, MouseEvent>
								) => {
									saveItem(MainCategory.Item);
								}}
							>
								{intl.formatMessage({
									id: "ClaimForm.AddNewItem",
								})}
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<div className="basket-wrapper">
					{insertItems()}
				</div>
				<Grid item md={10} xs={12}>
					<Dropzone
						files={files}
						removeFile={removeFile}
						loading={fileLoading}
						intl={dropzoneForReceipt}
						done={done}
						onDrop={onDrop}
						label={intl.formatMessage({
							id: "ClaimForm.AttachReceipt",
						})}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default ItemInputInformation;
