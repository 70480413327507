import React from "react";
import { Grid, FormGroup, Button, InputLabel } from "@material-ui/core";
import { useIntl } from "react-intl";
import { FieldGroupString } from "../../CommonComponents/FieldGroupString";
import ButtonComponent from "../../CommonComponents/ButtonComponent";
import { Claim, FieldType } from "../../../../models/IClaimFormDefaults";
import ItemInputInformation from "./ItemInputInformation";

interface IAccommodationInformationProps {
    claim: Claim;
    setValue: Function;
    done: boolean;
    setCategory: Function;
    saveCategory: Function;
    marketCurrency: string,
    allowTypeChange: boolean;
    savedItems: [];
	setSavedItems: Function;
    files: [File, string][];
	setFiles: Function;
    removeFile: Function;
    setError: Function;
    displayErrors: boolean;
    displayItemErrors: boolean;
}

const AccommodationInformation: React.FC<IAccommodationInformationProps> = (props) => {
    const { claim, setValue, done, setCategory, saveCategory, marketCurrency, allowTypeChange, savedItems, setSavedItems, files, setFiles, removeFile, setError, displayErrors, displayItemErrors } = props;
    const intl = useIntl();
    const [showItems, setShowItems] = React.useState(false);
    
    return (
        <>
            <Grid container spacing={3}>
                <Grid item md={7} xs={12}>
                    { allowTypeChange && <input type="button" className="text-button padding" 
						onClick={() => setCategory("")} value={intl.formatMessage({
						id: "ClaimForm.ReselectInsuranceType",
					})} /> }
                    <FieldGroupString
                        value={claim.accommodation?.street || ""}
                        onChange={setValue}
                        done={done}
                        multiLineTextBox
                        category="accommodation"
                        id="street"
                        name="street"
                        fieldType={FieldType.Text}
                        label={intl.formatMessage({
                            id: "ClaimForm.AccommodationStreetLabel",
                        }) + ' *'}
                        disabled={false}
                        placeholder={intl.formatMessage({
                            id: "ClaimForm.AccommodationStreetPlaceholder",
                        })}
                        isRequired
                        setError={setError}
                        displayErrors={displayErrors}
                    />

					<FieldGroupString
                        value={claim.accommodation?.street2 || ""}
                        onChange={setValue}
                        done={done}
                        multiLineTextBox
                        category="accommodation"
                        id="street2"
                        name="street2"
                        fieldType={FieldType.Text}
                        label={intl.formatMessage({
                            id: "ClaimForm.AccommodationStreet2Label",
                        })}
                        disabled={false}
                        placeholder={intl.formatMessage({
                            id: "ClaimForm.AccommodationStreet2Placeholder",
                        })}
						isRequired={false}
                        setError={setError}
                        displayErrors={displayErrors}
                    />

					<FieldGroupString
                        value={claim.accommodation?.zipCode || ""}
                        onChange={setValue}
                        done={done}
                        multiLineTextBox
                        category="accommodation"
                        id="zipCode"
                        name="zipCode"
                        fieldType={FieldType.Text}
                        label={intl.formatMessage({
                            id: "ClaimForm.AccommodationZipCodeLabel",
                        })}
                        disabled={false}
                        placeholder={intl.formatMessage({
                            id: "ClaimForm.AccommodationZipCodePlaceholder",
                        })}
                        isRequired={false}
                        setError={setError}
                        displayErrors={displayErrors}
                    />

					<FieldGroupString
                        value={claim.accommodation?.city || ""}
                        onChange={setValue}
                        done={done}
                        multiLineTextBox
                        category="accommodation"
                        id="city"
                        name="city"
                        fieldType={FieldType.Text}
                        label={intl.formatMessage({
                            id: "ClaimForm.AccommodationCityLabel",
                        }) + ' *'}
                        disabled={false}
                        placeholder={intl.formatMessage({
                            id: "ClaimForm.AccommodationCityPlaceholder",
                        })}
                        isRequired
                        setError={setError}
                        displayErrors={displayErrors}
                    />

					<FieldGroupString
                        value={claim.accommodation?.country || ""}
                        onChange={setValue}
                        done={done}
                        multiLineTextBox
                        category="accommodation"
                        id="country"
                        name="country"
                        fieldType={FieldType.Text}
                        label={intl.formatMessage({
                            id: "ClaimForm.AccommodationCountryLabel",
                        }) + ' *'}
                        disabled={false}
                        placeholder={intl.formatMessage({
                            id: "ClaimForm.AccommodationCountryPlaceholder",
                        })}
                        isRequired
                        setError={setError}
                        displayErrors={displayErrors}
                    />

                    <FieldGroupString
                        value={claim.accommodation?.apartmentNumber || ""}
                        onChange={setValue}
                        done={done}
                        multiLineTextBox
                        category="accommodation"
                        id="apartmentNumber"
                        name="apartmentNumber"
                        fieldType={FieldType.Text}
                        label={intl.formatMessage({
                            id: "ClaimForm.AccommodationNumberLabel",
                        })}
                        disabled={false}
                        placeholder={intl.formatMessage({
                            id: "ClaimForm.AccommodationNumberPlaceholder",
                        })}
                        isRequired={false}
                        setError={setError}
                        displayErrors={displayErrors}
                    />

                    <FieldGroupString
                        value={claim.accommodation?.propertyRegistrationNumber || ""}
                        onChange={setValue}
                        done={done}
                        multiLineTextBox
                        id="propertyRegistrationNumber"
                        category="accommodation"
                        name="propertyRegistrationNumber"
                        fieldType={FieldType.Text}
                        label={intl.formatMessage({
                            id: "ClaimForm.AccommodationRegistrationLabel",
                        })}
                        disabled={false}
                        placeholder={intl.formatMessage({
                            id: "ClaimForm.AccommodationRegistrationPlaceholder",
                        })}
                        isRequired={false}
                        setError={setError}
                        displayErrors={displayErrors}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormGroup id="formControlsCondition">
                        <ButtonComponent
                            value={claim.accommodation?.hasOwnerInsuranceCompany}
                            onChange={setValue}
                            done={done}
                            category="accommodation"
                            fieldName="hasOwnerInsuranceCompany"
                            label={intl.formatMessage({
                                id: "ClaimForm.HasAccommodationOwnerInsuranceCompany",
                            })}
                        />
                        {claim.accommodation &&
                            claim.accommodation?.hasOwnerInsuranceCompany === undefined &&
                            displayErrors ? (
                            <span className="buttonStateError">
                                {intl.formatMessage({
                                    id: "ClaimForm.validateFieldRequired",
                                })}
                            </span>
                        ) : (
                            <span />
                        )}
                    </FormGroup>
                </Grid>
                { claim.accommodation?.hasOwnerInsuranceCompany && <Grid item xs={12}>
                    <FieldGroupString
                        value={claim.accommodation?.ownerInsuranceCompany || ""}
                        onChange={setValue}
                        done={done}
                        multiLineTextBox
                        id="ownerInsuranceCompany"
                        category="accommodation"
                        fieldType={FieldType.Text}
                        name="ownerInsuranceCompany"
                        label={intl.formatMessage({
                            id: "ClaimForm.OwnerInsuranceCompany",
                        })}
                        placeholder={intl.formatMessage({
                            id: "ClaimForm.InsuranceCompany",
                        })}
                        isRequired={false}
                        setError={setError}
                        displayErrors={displayErrors}
                    />
                    {claim.accommodation && claim.accommodation.hasOwnerInsuranceCompany &&
                        !claim.accommodation.ownerInsuranceCompany &&
                        displayErrors ? (
                            <span className="buttonStateError">
                                {intl.formatMessage({
                                    id: "ClaimForm.validateFieldRequired",
                                })}
                            </span>
                        ) : (
                            <span />
                        )}
                </Grid>
                }
                {!showItems ?
                    <Grid item md={10} xs={12}>
                        <InputLabel>
                            {intl.formatMessage({
                                id: "ClaimForm.AddNewItem",
                            })}
                        </InputLabel>
                        <Button
                            disabled={done}
                            name="AddNewItem"
                            variant="contained"
                            onKeyPress={(e) => {
                                (e.key === "Enter" || e.key === " ") && setShowItems(true);
                            }}
                            onClick={(
                                e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                            ) => {
                                setShowItems(true);
                            }}
                        >
                            {intl.formatMessage({
                                id: "ClaimForm.AddNewItem",
                            })}
                        </Button>
                    </Grid>
                    :
                    <Grid item md={10} xs={12}>
                        <ItemInputInformation
                            claim={claim}
                            setValue={setValue}
                            done={done}
                            saveItem={saveCategory}
                            setCategory={setCategory}
                            marketCurrency={marketCurrency}
                            savedItems={savedItems}
                            setSavedItems={(items: any) => setSavedItems(items)}
                            files={files}
                            setFiles={setFiles}
                            removeFile={removeFile}
                            setError={setError}
                            displayErrors={displayItemErrors}
                        />
                    </Grid>
                }
            </Grid>
        </>
    );
};

export default AccommodationInformation;
