export const styles = {
	img: {
		display: "block" as "block",
		height: "100%",
		width: "auto",
	},

	thumb: {
		borderRadius: 2,
		boxSizing: "border-box" as "border-box", // A trick to prevent it being regarded as a string
		display: "inline-flex" as "inline-flex",
		height: 100,
		position: "relative" as "relative",
		marginBottom: 8,
		placeContent: "center" as "center",
		justifyContent: "center" as "center",
		marginRight: 8,
		padding: 4,
		width: 100,
	},

	thumbInner: {
		display: "flex" as "flex",
		minWidth: 0,
		overflow: "hidden" as "hidden",
	},

	thumbsContainer: {
		display: "flex" as "flex",
		flexDirection: "row" as "row",
		flexWrap: "wrap" as "wrap",
		marginTop: 16,
	},
};
