import { ExternalApiException } from "./ExternalApi";

declare module "./ExternalApi" {
	interface ExternalApiException {
		ExceptionFromExternalApi(): string;
	}
}

ExternalApiException.prototype.ExceptionFromExternalApi = function (
	this: ExternalApiException
): string {
	if (this.status === 400) {
		if (!this.response) {
			return "Bad request";
		}
		const apiError = JSON.parse(this.response).errors;
		return apiError !== undefined
			? Object.values(apiError).toString()
			: "Bad request";
	} else {
		return `Unexpected error`;
	}
};
