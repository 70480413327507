import "flatpickr/dist/flatpickr.min.css";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import "../../styles/css/Claim.css";
import "../../api/ExceptionFromExternalApi";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { checkIfFormValidates, ValidateItem } from "../../validation/FormValidation";
import NotificationContainer from "../ErrorHandling/Notifications/NotificationContainer";
import NotificationsManager from "../ErrorHandling/Notifications/NotificationsManager";
import { PrimaryButton } from "../../styles/styledComponents/styled";
import ClaimModal from "./ClaimModal";
import InsuranceInputInformation from "./ClaimFormComponents/InsuranceInputInformation";
import EventInputInformation from "./ClaimFormComponents/EventInputInformation";
import UserInputInformation from "./ClaimFormComponents/UserInputInformation";
import { Claim, IFieldProps, IModal } from "../../models/IClaimFormDefaults";
import { ExternalApiException, FileParameter, MainCategory, ProblemDetails } from "../../api/ExternalApi";
import { newClaimsClient } from "../../api/newClaimsClient";
import InsuredObjectTypeSelection from "./ClaimFormComponents/InsuredObjectTypeSelection";
import ItemInputInformation from "./ClaimFormComponents/InsuranceTypeComponents/ItemInputInformation";
import VehicleInformation from "./ClaimFormComponents/InsuranceTypeComponents/VehicleInformation";
import AccommodationInformation from "./ClaimFormComponents/InsuranceTypeComponents/AccommodationInformation";
import { appendToDescription, scrollIntoView } from "../../Utils/Utils";
// import ElectronicsInformation from "./ClaimFormComponents/InsuranceTypeComponents/ElectronicsInformation";
// import ParkingInformation from "./ClaimFormComponents/InsuranceTypeComponents/ParkingInformation";

interface IProps {
	claim: Claim;
	setValue: Function;
	pincode: string;
	setPincode: Function;
	claimState: IFieldProps;
	savedItems: any;
	setSavedItems: Function;
	marketCurrency: string;
	category: MainCategory;
	setCategory: Function;
	clearCategory: Function;
	showPincode: boolean;
	allowTypeChange: boolean;
	setAllowTypeChange: Function;
	insuranceIdError: boolean;
}

export const ClaimForm: React.FC<IProps> = (props: IProps) => {
	/** Initailization */

	const intl = useIntl();
	const { claim, setValue, pincode, setPincode, savedItems, setSavedItems, claimState, marketCurrency, category, setCategory, clearCategory, showPincode, allowTypeChange, setAllowTypeChange, insuranceIdError } = props;

	const [modal, setModal] = useState({} as IModal)
	const [error, setError] = useState({} as {[key: string]: string});
	const [files, setFiles] = useState([] as [File, string][]);
	const [receipts, setReceipts] = useState([] as [File, string][]);
	const [done, setDone] = useState(false);
	const [displayErrors, setDisplayErrors] = useState(false);
	const [displayItemErrors, setDisplayItemErrors] = useState(false);
	const minDate = claim.item?.dateOfPurchase;

	const handleSetError = (newError: string, id: string) => {
		if (newError){
			setError({...error, [id]: newError});
		} else {
			delete error[id];
			setError({...error});
		}
	}

	const saveCategory = () => {
		if(!ValidateItem(claim, intl)) {
			setDisplayItemErrors(true);
			return;
		}

		let items = savedItems;
		items.push(claim.item);
		
		setSavedItems(items);
		clearCategory();
		setAllowTypeChange(false);

		setDisplayItemErrors(false);
		NotificationsManager.info(
			intl.formatMessage({
				id: "ClaimForm.ItemAdded",
			})
		);
	}

	const categoryType = () => {
		switch (category) {
			case MainCategory.Item:
				return <ItemInputInformation
					claim={claim}
					setValue={setValue}
					done={done}
					saveItem={saveCategory}
					setCategory={setCategory}
					marketCurrency={marketCurrency}
					allowTypeChange={allowTypeChange}
					savedItems={savedItems}
					setSavedItems={(items: any) => setSavedItems(items)}
					files={receipts}
					setFiles={(files: [File, string][]) => setReceipts((oldFiles) => [...oldFiles, ...files])}
					removeFile={(files: [File, string][]) => setReceipts(files)}
					setError={handleSetError}
					displayErrors={displayItemErrors}
				/>
			case MainCategory.Vehicle:
				return <VehicleInformation
					claim={claim}
					setValue={setValue}
					done={done}
					setCategory={setCategory}
					saveCategory={saveCategory}
					marketCurrency={marketCurrency}
					allowTypeChange={allowTypeChange}
					savedItems={savedItems}
					setSavedItems={setSavedItems}
					files={receipts}
					setFiles={(files: [File, string][]) => setReceipts((oldFiles) => [...oldFiles, ...files])}
					removeFile={(files: [File, string][]) => setReceipts(files)}
					setError={handleSetError}
					displayErrors={displayErrors}
					displayItemErrors={displayItemErrors}
				/>
			case MainCategory.Accommodation:
				return <AccommodationInformation
					claim={claim}
					setValue={setValue}
					done={done}
					setCategory={setCategory}
					saveCategory={saveCategory}
					marketCurrency={marketCurrency}
					allowTypeChange={allowTypeChange}
					savedItems={savedItems}
					setSavedItems={setSavedItems}
					files={receipts}
					setFiles={(files: [File, string][]) => setReceipts((oldFiles) => [...oldFiles, ...files])}
					removeFile={(files: [File, string][]) => setReceipts(files)}
					setError={handleSetError}
					displayErrors={displayErrors} 
					displayItemErrors={displayItemErrors}
				/>
			// case MainCategory.Electronics:
			// 	return <ElectronicsInformation
			// 		claim={claim}
			// 		setValue={setValue}
			// 		done={done}
			// 		setError={handleSetError}
			// 		displayErrors={displayErrors} />
			// case MainCategory.Parking:
			// 	return <ParkingInformation
			// 		claim={claim}
			// 		setValue={setValue}
			// 		done={done}
			// 		setError={handleSetError}
			// 		displayErrors={displayErrors} />
			default:
				return <InsuredObjectTypeSelection
					setCategory={setCategory}
					done={done}
					displayErrors={displayErrors}
				/>
		}
	}

	const handleFormSubmit = async (
		e: React.SyntheticEvent<HTMLFormElement>
	) => {
		e.preventDefault();

		if (done) {
			return;
		}
		NotificationsManager.clear();

		// We have to clear the files as this may otherwise lead to adding already added files
		const claimAttachments = [] as FileParameter[];
		// eslint-disable-next-line no-empty-pattern
		for (const [file, {}] of files) {
			claimAttachments.push({
				data:file,
				fileName: file["name"]
			});
		}

		// eslint-disable-next-line no-empty-pattern
		for (const [file, {}] of receipts) {
			claimAttachments.push({
				data:file,
				fileName: file["name"]
			});
		}

		// Validation
		if(!checkIfFormValidates(claim, category, savedItems, intl, error, insuranceIdError)) {
			setDisplayErrors(true);
			setDisplayItemErrors(true);
			return;
		}

		setModal({
			...modal,
			showModal: true,
			modalHeader: "",
			modalText: intl.formatMessage({
				id: "ClaimForm.ModalTextSave",
			}),
			submitting: true,
		});

		setDisplayErrors(false);
		setDisplayItemErrors(false);

		let payload = [...[claim]][0];
		let items = savedItems;
		if (payload.item?.objectDescription &&
			payload.item?.dateOfPurchase &&
			payload.item?.placeOfPurchase &&
			payload.item?.objectPriceAmount) {
				items.push(payload.item);
		}

		switch (category) {
			case MainCategory.Item:
				payload.item = items;
				payload.vehicle = undefined;
				payload.accommodation = undefined;
				break;
			case MainCategory.Vehicle:
				payload = appendToDescription(payload, items)
				payload.item = undefined;
				payload.accommodation = undefined
				break;
			case MainCategory.Accommodation:
				payload = appendToDescription(payload, items)
				payload.item = undefined;
				payload.vehicle = undefined;
				break;
			default:
				break;
		}

		// This If statement can be removed once the backend allows the insuranceId field to be empty 
		if (!payload.insuranceId) {
			payload.insuranceId = "00000000-0000-0000-0000-000000000000"
		}

		const newRequest = newClaimsClient();
		try {
			const response = await newRequest.claimsClient.post(
				newRequest.platformType,
				JSON.stringify(payload),
				claimAttachments
			);

			setModal({
				...modal,
				modalHeader: intl.formatMessage({
					id: "ClaimForm.ModalHeaderClaimReceived",
				}),
				modalText: "",
				modalResponseIssueKey: response.issueKey ?? "",
				showModal: true,
				submitting: false,
			});
			setDone(true);

		} catch (ex) {
			// I am still not clear on why the generated code does not return a ValidationProblemDetails structure like it is done for
			// Omocom.Api/OpenApi/omocomApi.ts. If I can figure that out we might change this exception.

			// Display what the problem is in the error popup so we don't have to spin up the debugger to look at the exception
			if (ex instanceof ExternalApiException) {
				const responseErrorMessage = ex.ExceptionFromExternalApi();
				setModal({
					...modal,
					showModal: true,
					modalText: responseErrorMessage,
					modalHeader: intl.formatMessage({
						id: "ClaimForm.InstanceOfCreateClaimException",
					}),
					submitting: false,
				});
			} else if (ex instanceof Error) {
				setModal({
					...modal,
					showModal: true,
					modalText: ex.message,
					modalHeader: intl.formatMessage({
						id: "ClaimForm.InstanceOfError",
					}),
					submitting: false,
				});
			} else if (ex instanceof ProblemDetails) {
				setModal({
					...modal,
					showModal: true,
					modalText: ex.detail ?? intl.formatMessage({ id: "ClaimForm.UnidentifiedError" }),
					modalHeader: intl.formatMessage({
						id: "ClaimForm.InstanceOfError",
					}),
					submitting: false,
				});
			}
			else {
				setModal({
					...modal,
					showModal: true,
					modalText: intl.formatMessage({
						id: "ClaimForm.UnidentifiedError",
					}),
					modalHeader: intl.formatMessage({
						id: "ClaimForm.InstanceOfError",
					}),
					submitting: false,
				});
			}
		}
	};

	return (
		<>
			<form
				autoComplete="off"
				className="mb-4"
				onSubmit={(e) => handleFormSubmit(e)}
				noValidate
				onKeyPress={(event) => {
					if (event.key === "Enter") {
						event.preventDefault();
					}
				}}
			>
				<InsuranceInputInformation 
					claim={claim} 
					setValue={setValue}
					claimState={claimState}
					pincode={pincode}
					setPincode={setPincode}
					showPincode={showPincode} 
					done={done} 
					setError={handleSetError}
					displayErrors={displayErrors}
					insuranceIdError={insuranceIdError}
				/>
				<UserInputInformation 
					claim={claim}	
					setValue={setValue} 
					done={done} 
					setError={handleSetError}
					displayErrors={displayErrors}
				/>

				{categoryType()}

				<EventInputInformation
					claim={claim}
					setValue={setValue}
					claimState={claimState}
					done={done}
					files={files}
					setFiles={(files: [File, string][]) => setFiles((oldFiles) => [...oldFiles, ...files])}
					removeFile={(files: [File, string][]) => setFiles(files)}
					minDate={minDate}
					setError={handleSetError}
					displayErrors={displayErrors}
				/>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={8}>
						<Card>
							<CardContent>
								<Typography
									className="MuiTypography--heading"
									variant="h6"
									gutterBottom
								>
									{` ${intl.formatMessage({
										id: "ClaimForm.ReviewInformation",
									})}`}
								</Typography>
								<Typography
									className="MuiTypography--subheading"
									variant="caption"
								>
									{intl.formatMessage({
										id:
											"ClaimForm.ReviewYourInformationDetails",
									})}
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				</Grid>

				{claim.emailAddress &&
					<Grid container spacing={3}>
						<Grid item xs={12} sm={12} md={8}>
							<div className="warning-text">{intl.formatMessage({
								id:
									"ClaimForm.ReviewEmailUpperText",
							})}</div>
							<div className="email-check" onClick={() => { scrollIntoView("emailAddress") }}>
								<span>{claim.emailAddress}</span>
							</div>
							<div className="warning-text">{intl.formatMessage({
								id:
									"ClaimForm.ReviewEmailLowerText",
							})}</div>
						</Grid>
					</Grid>
				}

				<Grid container spacing={3}>
					<Grid item xs={12} sm={12} md={8}>
						<PrimaryButton
							className="btn btn-block"
							type="submit"
							value={intl.formatMessage({
								id: "ClaimForm.submitValue",
							})}
							disabled={done}
						/>
					</Grid>
				</Grid>

				<Grid container spacing={3} className="privacy-policy">
					<Grid item xs={12} sm={12} md={8}>
						<Card>
							<CardContent>
								<Typography
									className="MuiTypography--subheading"
									variant="caption"
								>
									{intl.formatMessage({
										id:
											"ClaimForm.PrivacyPolicyDetails",
									})}
								</Typography>
							</CardContent>
						</Card>
						<Card>
							<CardContent className="privacy-policy-text">
								<Typography
									className="MuiTypography--subheading"
									variant="caption"
								>
									{intl.formatMessage({
										id:
											"ClaimForm.PrivacyPolicyText",
									})} <a href={intl.formatMessage({
										id: "ClaimForm.PrivacyPolicyURL",
									})}>{intl.formatMessage({
										id: "ClaimForm.PrivacyPolicyURLText",
									})}
									</a>
								</Typography>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</form>

			<ClaimModal
				show={modal.showModal}
				header={modal.modalHeader}
				text={modal.modalText}
				logoResponse={modal.logoResponse}
				textIssueKey={modal.modalResponseIssueKey}
				email={claim.emailAddress}
				intl={intl}
				done={done}
				onCloseModal={() => {
					if (!modal.submitting) {
						setModal({
							...modal,
							showModal: false,
						});
					}
				}}
			/>
			<NotificationContainer />
		</>
	);
};
