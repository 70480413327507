import moment from "moment";
import { Accommodation, IncidentType, IItem, IVehicle } from "../api/ExternalApi";
import { localeLanguage } from "../Utils/translations/locales";

export interface IFieldProps {
	marketName: string;
	platformId: string;
	jiraIssueKey: string;
	platformDisplayName: string;
	insuranceStart: moment.Moment | undefined;
	insuranceEnd: moment.Moment | undefined;
}

export interface IModal {
	// Modal popup when you submit the claim and receive the response.
	submitting: boolean;
	logoResponse: any;
	modalHeader: string;
	modalText: string;
	modalResponseIssueKey: string;
	modalResponseBody: string;
	modalResponseFooter: string;
	modalResponseFooterLinkText: string;
	modalResponseFooterLink: string;
	showModal: boolean;
}

export interface Claim {
    ssn: string | undefined;
    externalId: string | undefined;
    firstName: string;
    lastName: string;
    phoneNumber: string | undefined;
    emailAddress: string;
    incidentType: IncidentType;
    insuranceId: string | undefined;
    dateOfDamage: moment.Moment | undefined;
    countryOfIncident: string | undefined;
    descriptionOfEvents: string;
    receiptExists: boolean;
    policeReportFiled: boolean;
    policeReportReference: string | undefined;
    swish: boolean;
    bankAccountNumber: string | undefined;
    locale: string | undefined;
    item: IItem | undefined;
    vehicle: IVehicle | undefined;
    accommodation: Accommodation | undefined;
    mainCategory: string;
}

export const validateForm = {} as { [key: string]: string };

// eslint-disable-next-line no-shadow
export enum FieldType {
	Email,
	Text,
	Phone,
	Date,
	Number,
	Checkbox,
	Boolean,
	Button,
	InsuranceId,
	Item,
	Vehicle,
	Accommodation,
	Pincode
}

export const defaultButtonTypes = [
	{
		name: "Yes", intl: "ClaimForm.Yes"
	}, {
		name: "No", intl: "ClaimForm.No"
	}
]

export const buttonIncidentTypes = [
	{
		name: "Damage", intl: "ClaimForm.IncidentTypeDamage",
	}, {
		name: "Theft", intl: "ClaimForm.IncidentTypeTheft"
	}, {
		name: "Loss", intl: "ClaimForm.IncidentTypeLoss"
	}
]

export const buttonObjectTypes = [
	{
		name: "Item", intl: "ClaimForm.ObjectTypeItem"
	}, {
		name: "Vehicle", intl: "ClaimForm.ObjectTypeVehicle"
	}, {
		name: "Accommodation", intl: "ClaimForm.ObjectTypeAccommodation"
	}, 
	// {
	// 	name: "Parking", intl: "ClaimForm.ObjectTypeParking"
	// }, {
	// 	name: "Electronics", intl: "ClaimForm.ObjectTypeElectronics"
	// }
]


export const initialValues = {
	locale: localeLanguage
}

export const getFillUpValues = () => {
	return {
		ssn: "20020308-5808",
		firstName: "Benny",
		lastName: "Tjäder",
		phoneNumber: "1233212332",
		emailAddress: "benny@tjader.com",
		incidentType: 2,
		vehicle: {
			hasOwnerInsuranceCompany: false,
			licensePlate: '90210',
			vehicleMaker: 'Volvo',
			vehicleModel: 'Volkswagen',
			vehicleYear: '1998',
		},
		dateOfDamage: moment().subtract(1, "days"),
		descriptionOfEvents:
			"I happened to accidentally use my shotgun on it. That did not work.",
	};
};

export const getFillUpItemValues = {
	externalId: undefined,
	ssn: "20020308-5808",
	firstName: "Benny",
	lastName: "Tjäder",
	phoneNumber: "1233212332",
	emailAddress: "benny@tjader.com",
	incidentType: 0,
	insuranceId: undefined,
	dateOfDamage: moment().subtract(25, "days"),
	countryOfIncident: undefined,
	descriptionOfEvents: "undefined",
	receiptExists: false,
	policeReportFiled: false,
	policeReportReference: undefined,
	swish: false,
	bankAccountNumber: undefined,
	locale: undefined,
	item: {
			objectDescription: "Chainsaw",
			dateOfPurchase: moment().subtract(30, "days"),
			placeOfPurchase: "undefined",
			objectNewAtPurchase: false,
			objectPriceAmount: 27,
			objectPriceCurrency: undefined
		},
	vehicle: undefined,
	accommodation: undefined,
	mainCategory: "Item",
	claimReporter: 2
}