import { Guid } from "guid-typescript";
import { ITermsAndConditions } from "../../models/ITermsAndConditions";
import DeploymentEnvironment from "../../services/DeploymentEnvironment";

export class TermsAndConditionsApi {
	public async getDocument(insuranceId: Guid) {
		const env = new DeploymentEnvironment(
			window.location.protocol,
			window.location.host
		);
		const baseUrl = env.apiUrl;

		const response = await fetch(
			`${baseUrl}/v1.0/TermsAndConditions/${insuranceId}`,
			{
				headers: {
					accept: "application/json",
				},
				method: "GET",
			}
		);

		if (response.status === 404) {
			return `Unknown insurance ID: ${insuranceId}`;
		}

		if (response.status < 200 || response.status >= 300) {
			throw new Error(
				`Failed to get insurance with Id ${insuranceId} (status ${response.status})`
			);
		}

		const termsAndConditions = (await response.json()) as ITermsAndConditions;
		return termsAndConditions.insuranceText;
	}
}
