import {
	makeStyles,
	createStyles,
	withStyles,
	Theme,
	InputBase,
} from "@material-ui/core";

export const useStylesTextField = makeStyles(() =>
	createStyles({
		root: {
			height: 60,
			overflow: "hidden",
			borderRadius: 4,
			backgroundColor: "#F8F9FB",
			paddingLeft: "0",
		},
	})
);

export const helperTextStyles = makeStyles(() => ({
	root: {
		height: "29px",
	},
	contained: {
		"& .MuiFormHelperText-contained": {
			marginLeft: "0px",
		},
	},
}));

export const useStylesTextArea = makeStyles(() =>
	createStyles({
		root: {
			overflow: "hidden",
			borderRadius: 4,
			backgroundColor: "#F8F9FB",
			marginBottom: "10px",
		},
	})
);

export const BootstrapInput = withStyles((theme: Theme) =>
	createStyles({
		input: {
			borderRadius: 4,
			position: "relative",
			backgroundColor: theme.palette.background.paper,
			border: "1px solid #ced4da",
			fontSize: 20,
			padding: "17px 26px 17px 12px",
			transition: theme.transitions.create([
				"border-color",
				"box-shadow",
			]),
			// Use the system font instead of the default Roboto font.
			fontFamily: [
				"-apple-system",
				"BlinkMacSystemFont",
				'"Segoe UI"',
				"Roboto",
				'"Helvetica Neue"',
				"Arial",
				"sans-serif",
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(","),
			"&:focus": {
				borderRadius: 4,
				borderColor: "#80bdff",
				boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
				backgroundColor: "white",
			},
		},
	})
)(InputBase);

export const useItemInputStyles = makeStyles(() =>
	createStyles({
		margin: {
			margin: "8px 0px",
		},
	})
);
