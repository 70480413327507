import React from "react";
import { Grid, FormGroup } from "@material-ui/core";
import { useIntl } from "react-intl";
import { FieldGroupString } from "../CommonComponents/FieldGroupString";
import ButtonComponent from "../CommonComponents/ButtonComponent";
import { IncidentType } from "../../../api/ExternalApi";
import { Claim, FieldType } from "../../../models/IClaimFormDefaults";

interface IPoliceReport {
	claim: Claim;
	setValue: Function;
	done: boolean;
	setError: Function;
	displayErrors: boolean;
}

const PoliceReport: React.FC<IPoliceReport> = (props: IPoliceReport) => {
	const {claim, setValue, done, setError, displayErrors} = props;
	const intl = useIntl();

	const convertValue = (value: any, fieldName: string, category: string) => {
		setValue(value, fieldName, category);
		if(value === false) {
            setValue("", "policeReportReference");
        }
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<FormGroup id="formControlsCondition">
					<ButtonComponent
						value={claim.policeReportFiled}
						onChange={convertValue}
						done={done}
						fieldName="policeReportFiled"
						label={intl.formatMessage({
							id: "ClaimForm.PoliceReportFiled",
						}) + " *"}
					/>
					{(claim.incidentType === IncidentType.Theft && claim.policeReportFiled === undefined) && displayErrors &&
						<span className="buttonStateError">
							{intl.formatMessage({
								id: "ClaimForm.validateFieldRequired",
							})}
						</span>
					}
				</FormGroup>
			</Grid>
			{claim.policeReportFiled &&
				<Grid item xs={12}>
					<FieldGroupString
						value={claim.policeReportReference || ""}
						onChange={setValue}
						done={done}
						multiLineTextBox
						id="policeReportReference"
						fieldType={FieldType.Text}
						name="policeReportReference"
						label={intl.formatMessage({
							id: "ClaimForm.policeReportReferenceLabel",
						})}
						disabled={!claim.policeReportFiled}
						placeholder={intl.formatMessage({
							id: "ClaimForm.policeReportReferencePlaceholder",
						})}
						isRequired={false}
						setError={setError}
						displayErrors={displayErrors}
					/>
				</Grid>
			}
		</Grid>
	);
};

export default PoliceReport;
