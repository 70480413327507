import React from "react";
import { InputLabel } from "@material-ui/core";
import { useIntl } from "react-intl";
import Popup from "reactjs-popup";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { defaultButtonTypes } from "../../../models/IClaimFormDefaults";

interface IButtonProps {
	value?: string | boolean;
	onChange: Function;
	done: boolean;
	fieldName: string;
	buttonTypes?: { name: string; intl: string; }[];
	category?: string;
	label?: string;
	information?: string
}

const ButtonComponent: React.FC<IButtonProps> = (props) => {
	/** Initialization */
	const { value, onChange, done, fieldName, category, label, information } = props;
	const intl = useIntl();

	const localButtonTypes = props.buttonTypes?.length ? props.buttonTypes : defaultButtonTypes;

	const setValueToBool = (value: string) => {
		if (value === "Yes") {
			return true;
		} else if (value === "No") {
			return false;
		} else {
			return value;
		}
	}

	const setValueFromBool = (value: string | boolean | undefined) => {
		if (value === true) {
			return "Yes";
		} else if (value === false) {
			return "No";
		} else {
			return value;
		}
	}

	return (
		<>
			<Popup
				trigger={() => (
					<InputLabel>
						{label ?? ""}
						{information && <div className="info-trigger">{"\u24d8"}</div>}
					</InputLabel>
				)}
				on={['click', 'hover']}
				position="right center"
				closeOnDocumentClick
			>
				{information && <div className="input-info">
					{information}
				</div>}
			</Popup>
			<div
				className="pt-3 pb-3 btn-group-toggle buttonState"
				data-toggle="buttons"
			>
				<ToggleButtonGroup
					className="pt-3 pb-3 btn-group-toggle buttonState"
					name={fieldName}
					value={setValueFromBool(value)}
					onChange={(value: any) => {onChange(setValueToBool(value), fieldName, category)}}
				>
					{localButtonTypes.map((buttonType: { name: string; intl: string; }) => (
						<ToggleButton
							className="MuiButton-contained"
							disabled={done}
							value={buttonType.name}
							variant="contained"
							key={`${fieldName} - ${buttonType.name}`}
						>
							{intl.formatMessage({
								id: buttonType.intl,
							})}
						</ToggleButton>
					))}
				</ToggleButtonGroup>
			</div>
		</>
	);
};

export default ButtonComponent;
