import moment from "moment";
import { buttonObjectTypes } from "../models/IClaimFormDefaults";

const scrollIntoView = (name: string) => {
    document.getElementsByName(name)[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
    document.getElementsByName(name)[0].style.border = "2px solid red";
}

const removeEmpty = (obj: Object | undefined) => {
    if (!obj) {
        return obj;
    }

    const newObj = {};
    const types = buttonObjectTypes;
    Object.entries(obj).forEach(([key, value]) => {
        // Only performs recursion of nested objects on items where the key is included in the types const
        if (value === Object(value) && types.some(e => e.name === key.toLowerCase())) {
            newObj[key] = removeEmpty(value);
        } else if (value != null) {
            newObj[key] = obj[key];
        }
    });
    return newObj;
}

const appendToDescription = (payload: any, savedItems: any) => {
    let appendedPayload = payload;
    if (savedItems.length) {
        appendedPayload.descriptionOfEvents += "\n\nItems:"

        for (let i = 0; i < savedItems.length; i++) {
            appendedPayload.descriptionOfEvents += "\n\n" +
                "Object Description: " + savedItems[i]?.objectDescription + "\n" +
                "Date of Purchase: " + moment(savedItems[i]?.dateOfPurchase).format("MMMM Do YYYY") + "\n" +
                "Place of Purchase: " + savedItems[i]?.placeOfPurchase + "\n" +
                "Object Price: " + savedItems[i]?.objectPriceAmount + " " + (savedItems[i]?.objectPriceCurrency && savedItems[i]?.objectPriceCurrency);
        }
    }
    return appendedPayload;
}

export { scrollIntoView, removeEmpty, appendToDescription }
