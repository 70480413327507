import React from "react";
import { Grid, FormGroup, Button, InputLabel } from "@material-ui/core";
import { useIntl } from "react-intl";
import { FieldGroupString } from "../../CommonComponents/FieldGroupString";
import ButtonComponent from "../../CommonComponents/ButtonComponent";
import { Claim, FieldType } from "../../../../models/IClaimFormDefaults";
import ItemInputInformation from "./ItemInputInformation";

interface IVehicleInformationProps {
	claim: Claim;
	setValue: Function;
	done: boolean;
	setCategory: Function;
	saveCategory: Function;
	marketCurrency: string,
	allowTypeChange: boolean;
	savedItems: [];
	setSavedItems: Function;
	files: [File, string][];
	setFiles: Function;
	removeFile: Function;
	setError: Function;
	displayErrors: boolean;
	displayItemErrors: boolean;
}

const VehicleInformation: React.FC<IVehicleInformationProps> = (props) => {
	const { claim, setValue, done, setCategory, saveCategory, marketCurrency, allowTypeChange, savedItems, setSavedItems, files, setFiles, removeFile, setError, displayErrors, displayItemErrors } = props;
	const intl = useIntl();
	const [showItems, setShowItems] = React.useState(false);

	return (
		<>
			<Grid item md={7} xs={12}>
				{allowTypeChange && <input type="button" className="text-button padding"
					onClick={() => setCategory("")} value={intl.formatMessage({
						id: "ClaimForm.ReselectInsuranceType",
					})} />}
				<FieldGroupString
					value={claim.vehicle?.licensePlate || ""}
					onChange={setValue}
					done={done}
					multiLineTextBox
					id="licensePlate"
					category="vehicle"
					name="licensePlate"
					fieldType={FieldType.Text}
					label={intl.formatMessage({
						id: "ClaimForm.VehicleLicensePlateLabel",
					}) + " *"}
					disabled={false}
					placeholder={intl.formatMessage({
						id: "ClaimForm.VehicleLicensePlatePlaceholder",
					})}
					isRequired
					setError={setError}
					displayErrors={displayErrors}
				/>

				<FieldGroupString
					value={claim.vehicle?.vehicleMaker || ""}
					onChange={setValue}
					done={done}
					multiLineTextBox
					id="vehicleMaker"
					category="vehicle"
					name="vehicleMaker"
					fieldType={FieldType.Text}
					label={intl.formatMessage({
						id: "ClaimForm.VehicleMakeLabel",
					})}
					disabled={false}
					placeholder={intl.formatMessage({
						id: "ClaimForm.VehicleMakePlaceholder",
					})}
					isRequired={false}
					setError={setError}
					displayErrors={displayErrors}
				/>

				<FieldGroupString
					value={claim.vehicle?.vehicleModel || ""}
					onChange={setValue}
					done={done}
					multiLineTextBox
					id="vehicleModel"
					category="vehicle"
					name="vehicleModel"
					fieldType={FieldType.Text}
					label={intl.formatMessage({
						id: "ClaimForm.VehicleModelLabel",
					})}
					disabled={false}
					placeholder={intl.formatMessage({
						id: "ClaimForm.VehicleModelPlaceholder",
					})}
					isRequired={false}
					setError={setError}
					displayErrors={displayErrors}
				/>

				<FieldGroupString
					value={claim.vehicle?.vehicleYear || ""}
					onChange={setValue}
					done={done}
					multiLineTextBox
					id="vehicleYear"
					category="vehicle"
					name="vehicleYear"
					fieldType={FieldType.Text}
					label={intl.formatMessage({
						id: "ClaimForm.VehicleModelYearLabel",
					})}
					disabled={false}
					placeholder={intl.formatMessage({
						id: "ClaimForm.VehicleModelYearPlaceholder",
					})}
					isRequired={false}
					setError={setError}
					displayErrors={displayErrors}
				/>
				<Grid item xs={12}>
					<FormGroup id="formControlsCondition">
						<ButtonComponent
							value={claim.vehicle?.hasCollision}
							onChange={setValue}
							category="vehicle"
							done={done}
							fieldName="hasCollision"
							label={intl.formatMessage({
								id: "ClaimForm.HasVehicleBeenInCollision",
							})}
						/>
					</FormGroup>
				</Grid>
				{claim.vehicle?.hasCollision &&
					<Grid item xs={12}>
						<FieldGroupString
							value={claim.vehicle?.thirdPartyLicensePlate || ""}
							onChange={setValue}
							done={done}
							multiLineTextBox
							id="thirdPartyLicensePlate"
							category="vehicle"
							name="thirdPartyLicensePlate"
							fieldType={FieldType.Text}
							label={intl.formatMessage({
								id: "ClaimForm.VehicleThirdPartyLicensePlateLabel",
							})}
							disabled={false}
							placeholder={intl.formatMessage({
								id: "ClaimForm.VehicleThirdPartyLicensePlatePlaceholder",
							})}
							isRequired={false}
							setError={setError}
							displayErrors={displayErrors}
						/>
						<FieldGroupString
							value={claim.vehicle?.thirdPartyInsuranceCompany || ""}
							onChange={setValue}
							done={done}
							multiLineTextBox
							id="thirdPartyInsuranceCompany"
							category="vehicle"
							name="thirdPartyInsuranceCompany"
							fieldType={FieldType.Text}
							label={intl.formatMessage({
								id: "ClaimForm.VehicleThirdPartyInsuranceCompanyLabel",
							})}
							disabled={false}
							placeholder={intl.formatMessage({
								id: "ClaimForm.VehicleThirdPartyInsuranceCompanyPlaceholder",
							})}
							isRequired={false}
							setError={setError}
							displayErrors={displayErrors}
						/>
					</Grid>
				}
				<FieldGroupString
					value={claim.vehicle?.locationOfIncident || ""}
					onChange={setValue}
					done={done}
					multiLineTextBox
					id="locationOfIncident"
					category="vehicle"
					name="locationOfIncident"
					fieldType={FieldType.Text}
					label={intl.formatMessage({
						id: "ClaimForm.LocationOfIncidentLabel",
					})}
					disabled={false}
					placeholder={intl.formatMessage({
						id: "ClaimForm.LocationOfIncidentPlaceholder",
					})}
					isRequired={false}
					setError={setError}
					displayErrors={displayErrors}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormGroup id="formControlsCondition">
					<ButtonComponent
						value={claim.vehicle?.hasOwnerInsuranceCompany}
						onChange={setValue}
						category="vehicle"
						done={done}
						fieldName="hasOwnerInsuranceCompany"
						label={intl.formatMessage({
							id: "ClaimForm.HasVehicleOwnerInsuranceCompany",
						})}
						information={intl.formatMessage({
							id: "ClaimForm.HasVehicleOwnerInsuranceCompanyDescription",
						})}
					/>
					{claim.vehicle && claim.vehicle.hasOwnerInsuranceCompany === undefined &&
						props.displayErrors ? (
						<span className="buttonStateError">
							{intl.formatMessage({
								id: "ClaimForm.validateFieldRequired",
							})}
						</span>
					) : (
						<span />
					)}
				</FormGroup>
			</Grid>
			{claim.vehicle?.hasOwnerInsuranceCompany &&
				<Grid item xs={12}>
					<FieldGroupString
						value={claim.vehicle?.ownerInsuranceCompany || ""}
						onChange={setValue}
						done={done}
						multiLineTextBox
						id="ownerInsuranceCompany"
						category="vehicle"
						fieldType={FieldType.Text}
						name="ownerInsuranceCompany"
						label={intl.formatMessage({
							id: "ClaimForm.OwnerInsuranceCompany",
						})}
						placeholder={intl.formatMessage({
							id: "ClaimForm.InsuranceCompany",
						})}
						isRequired={false}
						setError={setError}
						displayErrors={displayErrors}
					/>
					{claim.vehicle && claim.vehicle.hasOwnerInsuranceCompany &&
						!claim.vehicle.ownerInsuranceCompany &&
						props.displayErrors ? (
						<span className="buttonStateError">
							{intl.formatMessage({
								id: "ClaimForm.validateFieldRequired",
							})}
						</span>
					) : (
						<span />
					)}
				</Grid>
			}
			{!showItems ?
				<Grid item md={10} xs={12}>
					<InputLabel>
						{intl.formatMessage({
							id: "ClaimForm.AddNewItem",
						})}
					</InputLabel>
					<Button
						disabled={done}
						name="AddNewItem"
						variant="contained"
						onKeyPress={(e) => {
							(e.key === "Enter" || e.key === " ") && setShowItems(true);
						}}
						onClick={(
							e: React.MouseEvent<HTMLButtonElement, MouseEvent>
						) => {
							setShowItems(true);
						}}
					>
						{intl.formatMessage({
							id: "ClaimForm.AddNewItem",
						})}
					</Button>
				</Grid>
				:
				<Grid item md={10} xs={12}>
					<ItemInputInformation
						claim={claim}
						setValue={setValue}
						done={done}
						saveItem={saveCategory}
						setCategory={setCategory}
						marketCurrency={marketCurrency}
						savedItems={savedItems}
						setSavedItems={(items: any) => setSavedItems(items)}
						files={files}
						setFiles={setFiles}
						removeFile={removeFile}
						setError={setError}
						displayErrors={displayItemErrors}
					/>
				</Grid>
			}
		</>
	);
};

export default VehicleInformation;