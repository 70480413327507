import { Guid } from "guid-typescript";
import React, { useEffect, useState } from "react";
import { TermsAndConditionsApi } from "./TearmsAndConditionsApi";

interface IProps {
	insuranceId: Guid;
}

export function TermsAndConditions({ insuranceId }: IProps) {
	const [insuranceText, setInsuranceText] = useState("");

	useEffect(() => {
		const fetchData = async () => {
			const src = new TermsAndConditionsApi();
			const text = await src.getDocument(insuranceId);

			setInsuranceText(text);
		};

		fetchData();
	}, [insuranceId]);

	return (
		<>
			<pre>{insuranceText}</pre>
		</>
	);
}
