import React, { useState } from "react";
import { FormGroup, Grid } from "@material-ui/core";
import { useIntl } from "react-intl";
import { IncidentType, } from "../../../api/ExternalApi";
import { Claim, buttonIncidentTypes, FieldType, IFieldProps } from "../../../models/IClaimFormDefaults";
import { FieldGroupString } from "../CommonComponents/FieldGroupString";
import Dropzone from "./Dropzone/Dropzone";
import { handleFileDropped } from "./Dropzone/handleDropzone";
import PoliceReport from "./PoliceReport";
import ButtonComponent from "../CommonComponents/ButtonComponent";
import DateComponent from "../CommonComponents/DateComponent";
import CountryOfIncident from "./CountryOfIncident";

interface IEventInputInformationProps {
	claim: Claim;
	setValue: Function;
	claimState: IFieldProps;
	files: [File, string][];
	setFiles: Function;
	removeFile: Function;
	minDate?: any;
	done: boolean;
	setError: Function;
	displayErrors: boolean;
}

const EventInputInformation: React.FC<IEventInputInformationProps> = (props) => {
	/** Initialization */
	const { claim, setValue, claimState, minDate, done, files, setFiles, removeFile, setError, displayErrors } = props;
	const [fileLoading, setFileLoading] = useState(false);
	const intl = useIntl();

	const convertValue = (value: any, fieldName: string, category: string) => {
		setValue(IncidentType[value], fieldName, category);
		if(value !== IncidentType.Theft) {
            setValue(undefined, "policeReportFiled");
			setValue("", "policeReportReference");
        }
	}

	const dropzoneForDamageObject = {
		acceptValue: intl.formatMessage({
			id: "ClaimForm.isDragAcceptforDamageObject",
		}),
		rejectValue: intl.formatMessage({
			id: "ClaimForm.isDragRejectforDamageObject",
		}),
		activeValue: intl.formatMessage({
			id: "ClaimForm.isDragInActiveforDamageObject",
		}),
		thumbs: intl.formatMessage({
			id: "ClaimForm.thumbs",
		}),
		allowedFormats: intl.formatMessage({
			id: "ClaimForm.AllowedFileFormatOfDamageObject",
		}),
		resizingImage: intl.formatMessage({
			id: "ClaimForm.ResizingImage",
		})
	};

	// DropZone
	const onDrop = async (acceptedFiles: File[]) => {
		setFileLoading(true);
		let tempFiles = await handleFileDropped(acceptedFiles, files, intl);
		setFileLoading(false);
		tempFiles && setFiles(tempFiles);
	}

	return (
		<Grid container spacing={3}>
			<Grid item md={7} xs={12} style={{ paddingBottom: "0" }}>
				<FormGroup id="formControlsCause">
					<ButtonComponent
						value={IncidentType[claim.incidentType]}
						onChange={convertValue}
						done={done}
						fieldName="incidentType"
						buttonTypes={buttonIncidentTypes}
						label={intl.formatMessage({
							id: "ClaimForm.IncidentType",
						}) + " *"}
					/>
					{claim.incidentType === undefined &&
						displayErrors && (
							<span className="buttonStateError">
								{intl.formatMessage({
									id: "ClaimForm.validateFieldRequired",
								})}
							</span>
						)}
				</FormGroup>
			</Grid>
			<Grid item md={7} xs={12} style={{ paddingTop: "2px" }}>
				{claim.incidentType === IncidentType.Theft &&
					<PoliceReport
						claim={claim}
						setValue={setValue}
						done={done}
						setError={setError}
						displayErrors={displayErrors}
					/>
				}
			</Grid>
			<Grid item md={7} xs={12}>
				<DateComponent
					value={claim.dateOfDamage}
					onChange={setValue}
					done={done}
					id="dateOfDamage"
					fieldName="dateOfDamage"
					label={intl.formatMessage({
						id: "ClaimForm.dateOfDamageLabel",
					}) + " *"}
					placeholder={intl.formatMessage({
						id: "ClaimForm.dateOfDamagePlaceholder",
					})}
					flatPickerOptions={{
						enableTime: true,
						dateFormat: "Y-m-d H:i",
						time_24hr: true,
						allowInput: true,
						minDate: minDate ?? "2010-01-01"
					}}
					isRequired
					displayErrors={displayErrors}
				/>
				<CountryOfIncident 
					claim={claim}
					onChange={setValue}
					claimState={claimState}
					label={intl.formatMessage({
						id: "ClaimForm.countryOfDamageLabel",
					})}
					done={done}
				/>
			</Grid>
			<Grid item md={8} xs={12}>
				<FormGroup
					id="descriptionOfEvents"
					className={
						displayErrors ? "was-validated" : ""
					}
				>
					<FieldGroupString
						value={claim.descriptionOfEvents || ""}
						onChange={setValue}
						done={done}
						id="descriptionOfEvents"
						name="descriptionOfEvents"
						fieldType={FieldType.Text}
						multiLineTextBox={false}
						label={intl.formatMessage({
							id: "ClaimForm.DescriptionOfEvents",
						}) + " *"}
						disabled={done}
						placeholder={intl.formatMessage({
							id: "ClaimForm.descriptionOfEventsPlaceholder",
						})}
						isRequired
						setError={setError}
						displayErrors={displayErrors}
					/>
				</FormGroup>
			</Grid>
			<Grid item md={10} xs={12}>
				<Dropzone
					files={files}
					removeFile={removeFile}
					loading={fileLoading}
					intl={dropzoneForDamageObject}
					done={done}
					onDrop={onDrop}
					label={intl.formatMessage({
						id: "ClaimForm.UploadDamagePicture",
					})}
					information={intl.formatMessage({
						id: "ClaimForm.InformationTextUploadImages",
					})}
				/>
			</Grid>
		</Grid>
	);
};

export default EventInputInformation;
