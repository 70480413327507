import { EventEmitter } from "events";

import { NotificationMessage } from "./NotificationMessage";
import { NotificationType } from "./NotificationType";

/**
 * Service for handling notifications for the application.
 */
class NotificationsManager extends EventEmitter {
	private visibleNotifications: NotificationMessage[];

	private nextId = 0;

	private static changeEventKey = "CHANGE";

	constructor() {
		super();
		this.visibleNotifications = [];
	}

	/**
	 * Creates a new notification
	 *
	 * @param type The type of notification, the type will affect the notification styling
	 * @param message The message to display
	 * @param title (optional) An optional title to display
	 * @param timeOut The amount of milliseconds to display the notification, if set to 0 the notification will not close automatically
	 */
	public create(
		type: NotificationType,
		message: string,
		title?: string,
		timeOut = 5000
	): void {
		this.visibleNotifications.push(
			new NotificationMessage({
				id: this.nextId += 1,
				type,
				title,
				message,
				timeout: timeOut,
			})
		);
		this.emitChange();
	}

	/**
	 * Display an information notification message
	 *
	 * @param message The message to display
	 * @param title (optional) An optional title to display
	 * @param timeOut The amount of milliseconds to display the notification, if set to 0 the notification will not close automatically
	 */
	public info(message: string, title?: string, timeOut = 5000): void {
		this.create(NotificationType.Info, message, title, timeOut);
	}

	/**
	 * Display a warning notification message
	 *
	 * @param message The message to display
	 * @param title (optional) An optional title to display
	 * @param timeOut The amount of milliseconds to display the notification, if set to 0 the notification will not close automatically
	 */
	public warn(message: string, title?: string, timeOut = 5000): void {
		this.create(NotificationType.Warning, message, title, timeOut);
	}

	/**
	 * Display an error information message
	 *
	 * @param message The message to display
	 * @param title (optional) An optional title to display
	 * @param timeOut The amount of milliseconds to display the notification, if set to 0 the notification will not close automatically
	 */
	public error(message: string, title?: string, timeOut = 5000): void {
		this.create(NotificationType.Error, message, title, timeOut);
	}

	/**
	 * Removes a specific notification message from the displayed messages (closes the given notification)
	 *
	 * @param notification The notification to remove (close)
	 */
	public remove(notification: NotificationMessage): void {
		this.visibleNotifications = this.visibleNotifications.filter(
			(n) => notification.id !== n.id
		);
		this.emitChange();
	}

	/**
	 * Clears and closes all active notification messages
	 */
	public clear(): void {
		this.visibleNotifications = [];
		this.emitChange();
	}

	/**
	 * Add a change listener that is notified when notification array changes
	 *
	 * @param listener The listener function
	 */
	public addChangeListener(listener: (...args: any[]) => void) {
		this.addListener(NotificationsManager.changeEventKey, listener);
	}

	/**
	 * Remove an existing change listener from the current listeners
	 *
	 * @param listener The listener to remove
	 */
	public removeChangeListener(listener: (...args: any[]) => void) {
		this.removeListener(NotificationsManager.changeEventKey, listener);
	}

	/**
	 * Emits a change message to all change listeners
	 */
	private emitChange(): void {
		this.emit(NotificationsManager.changeEventKey, [
			...this.visibleNotifications,
		]);
	}
}

export default new NotificationsManager();
