import React from "react";
import { Logo } from "../../../styles/styledComponents/styled";
import { Button, Grid, MenuItem, Select } from "@material-ui/core";
import { Header } from "./Header";
import { Claim, MainCategory } from "../../../api/ExternalApi";
import { useIntl } from "react-intl";
import { IFieldProps, getFillUpValues } from "../../../models/IClaimFormDefaults";
import DeploymentEnvironment, { DeploymentEnv } from "../../../services/DeploymentEnvironment";
import { languages } from "../../../models/IClaimInfo";
import ReactCountryFlag from "react-country-flag";

interface IProps {
	claimState: IFieldProps;
	setClaim: Function;
	locale: string;
	setLocale: Function;
}

const ClaimsHeader = (props: IProps) => {
	const intl = useIntl();
	const env = new DeploymentEnvironment(
		window.location.protocol,
		window.location.host
	);
	const handleFillDebugValuesClicked = (): void => {
		const initialValue = getFillUpValues() as Claim;
		props.setClaim(initialValue, MainCategory.Vehicle);
	};
	const fixShortForm = (code: string) => {
		if (code === "sv") {
			return "se";
		} else if (code === "da") {
			return "dk";
		} else if (code === "en") {
			return "gb";
		} else {
			return code;
		}
	}

	return (
		<>
			<div>
				<Logo />
				{/* {props.intl({
					id: "ClaimForm.formPreTitle",
				})} */}
			</div>
			<Grid container spacing={3}>
				<Grid item md={12} xs={12}>
					<div className="locale-select-wrapper">
						<Select className="locale-select" name="select" onChange={(value: any) => props.setLocale(value.currentTarget.dataset.value)} defaultValue={props.locale}>
							{languages.map((locale) => {
								return (<MenuItem key={locale.name} value={locale.shortForm}>
									<ReactCountryFlag className="header-flag" countryCode={fixShortForm(locale.shortForm)} svg />{locale.label}
								</MenuItem>
								)
							})}
						</Select>
					</div>
				</Grid>
			</Grid>
			{env.Type === DeploymentEnv.Local &&
				<>
					<Button variant="contained" onClick={handleFillDebugValuesClicked}>
						Fill debug values
					</Button>
					<Select className="locale-select dev" name="select" onChange={(value: any) => props.setLocale(value.currentTarget.dataset.value)} defaultValue={props.locale}>
						{languages.map((locale) => {
							return (<MenuItem key={locale.name} value={locale.shortForm}>
								<ReactCountryFlag className="header-flag" countryCode={fixShortForm(locale.shortForm)} svg />{locale.label}
							</MenuItem>
							)
						})}
					</Select>
				</>
			}
			<Header
				headerTitle={intl.formatMessage({
					id: "ClaimForm.FormHeaderTypeDamage",
				})}
				ingress={intl.formatMessage({ id: "ClaimForm.FormHeaderIngress" })}
				text={intl.formatMessage({ id: "ClaimForm.FormHeaderText" })}
				alert={props.claimState.platformId === "Blocket" ? intl.formatMessage({ id: "ClaimForm.BlocketPaketText" }) : undefined}
			/>
		</>
	);
};

export default ClaimsHeader;
