export enum DeploymentEnv {
	Local,
	AzurePrivate,
	AzureDevelopment,
	Beta,
	Systest,
	Staging,
	Production,
}

export default class DeploymentEnvironment {
	private protocol: string;

	private environmentType: DeploymentEnv;

	private environmentName: string;

	constructor(protocol?: string, url?: string) {
		this.protocol = protocol || window.location.protocol;
		[this.environmentType, this.environmentName] =
			this.GetEnvironmentTypeAndName(url || window.location.host);
	}

	public get Type(): DeploymentEnv {
		return this.environmentType;
	}

	public get apiUrl(): string {
		switch (this.environmentType) {
			case DeploymentEnv.Local: {
				return `${this.protocol}//localhost:5011`;
			}
			case DeploymentEnv.AzurePrivate: {
				return `${this.protocol}//omocom-${this.environmentName}-external-api.azurewebsites.net`;
			}
			case DeploymentEnv.AzureDevelopment:
			case DeploymentEnv.Beta:
			case DeploymentEnv.Systest:
			case DeploymentEnv.Staging: {
				return `${this.protocol}//external-api-${this.environmentName}.dev.omocom.se`;
			}
			case DeploymentEnv.Production: {
				return `${this.protocol}//external-api.omocom.se`;
			}
			default: {
				throw new Error(
					`Unknown DeploymentEnv: ${this.environmentType}`
				);
			}
		}
	}

	public get appInsightsIKey() {
		switch (this.environmentType) {
			case DeploymentEnv.Local: {
				return "123";
			}
			case DeploymentEnv.AzurePrivate: {
				return "456";
			}
			case DeploymentEnv.AzureDevelopment: {
				return "456";
			}
			case DeploymentEnv.Beta: {
				return "3434";
			}
			case DeploymentEnv.Staging: {
				return "555";
			}
			case DeploymentEnv.Production: {
				return "777";
			}
			default: {
				throw new Error(
					`Unknown DeploymentEnv: ${this.environmentType}`
				);
			}
		}
	}

	private GetEnvironmentTypeAndName(url: string): [DeploymentEnv, string] {
		let compareString = url.toLowerCase();
		compareString = compareString.split(":")[0];
		switch (compareString) {
			case "localhost":
				return [DeploymentEnv.Local, "local"];
			case "external-develop.dev.omocom.se":
				return [DeploymentEnv.AzureDevelopment, "develop"];
			case "external-beta.omocom.se":
				return [DeploymentEnv.Beta, "beta"];
			case "external-systest.dev.omocom.se":
				return [DeploymentEnv.Systest, "systest"];
			case "external-staging.omocom.se":
				return [DeploymentEnv.Staging, "staging"];
			case "external.omocom.se":
				return [DeploymentEnv.Production, "production"];
		}

		const urlRegExp = /omocom-(.+)-external.azurewebsites.net/;
		const match = urlRegExp.exec(compareString);
		if (match == null) {
			throw new Error(`Unknown URL passed to environment: ${url}`);
		}

		return [DeploymentEnv.AzurePrivate, match[1]];
	}
}
