import { FormHelperText, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { newClaimsClient } from "../../../api/newClaimsClient";
import { SupportedCurrencies } from "../../../models/MonetaryValue";
import { BootstrapInput } from "../../../styles/styledComponents/stylesForComponents";
import { Claim } from "../../../models/IClaimFormDefaults";

const CurrencyComponent = (props: { claim: Claim, marketCurrency: string, onChange: Function }) => {
	const { claim, marketCurrency, onChange } = props

	const [fetchedCurrencyData, setFetchedCurrencyData] = useState(["SEK"]);
	const [showSelectChangeHelperText, setShowSelectChangeHelperText] = useState("");

	const handleCurrencyChange = (event: any) => {
		if (marketCurrency) {
			setShowSelectChangeHelperText("OOPS! You cant change the currency.");
		} else {
			onChange(event.currentTarget.textContent as SupportedCurrencies, "objectPriceCurrency", "item");
		}
	};

	// Currency
	const showFetchedCurrencyDataItems = (currencyList: string[]) => {
		if (!currencyList.length) {
			return;
		}

		return currencyList.map((c) => (
			<MenuItem key={c} value={c}>
				{c}
			</MenuItem>
		));
	};

	const handleFetchedCurrencyData = async () => {
		const newRequest = newClaimsClient();
		try {
			const response = await newRequest.claimsClient.currenciesList(newRequest.platformType, "");
			let tempArr = [] as string[];
			if (response) {
				response.forEach((element: string) => {
					tempArr.push(element);
				});

				setFetchedCurrencyData(tempArr);
				onChange(response[0], "objectPriceCurrency", "item");
			}
		}
		catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		if(!marketCurrency){
			handleFetchedCurrencyData();
		}
		else {
			setFetchedCurrencyData([marketCurrency]);
		}
	// eslint-disable-next-line
	}, []);

	return (
		<>
			<Select
				labelId="objectPriceCurrency"
				id="objectPrice"
				onChange={handleCurrencyChange}
				value={marketCurrency || claim.item?.objectPriceCurrency || "SEK"}
				input={<BootstrapInput />}
				fullWidth
			>
				{showFetchedCurrencyDataItems(fetchedCurrencyData)}
			</Select>
			<FormHelperText style={{ color: "red" }}>
				{showSelectChangeHelperText}
			</FormHelperText>
		</>
	);
};
export default CurrencyComponent;
