/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { InputLabel } from "@material-ui/core";
import React from "react";
import { useDropzone } from "react-dropzone";
import Popup from "reactjs-popup";
import { styles } from "../../../../styles/styledComponents/Styles";

interface IProps {
	onDrop: (acceptedFiles: File[]) => void;
	files: [File, string][];
	removeFile: Function;
	loading: Boolean;
	done: boolean;
	label?: string;
	information?: string;
	intl: {
		acceptValue: string;
		rejectValue: string;
		activeValue: string;
		thumbs: string;
		allowedFormats: string;
		resizingImage: string;
	};
}

const Dropzone = (props: IProps) => {
	const { onDrop, files, removeFile, loading, done, label, information, intl } = props;

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		accept: "image/jpeg, image/png, image/jpg, image/bmp, image/webp, .pdf",
		minSize: 0,
		multiple: true,
		onDrop,
	});

	const addRemoveIcon = (index: number) => (
		<button
			type="button"
			onClick={() => {
				const tempRemoveFile = [...files];
				tempRemoveFile.splice(index, 1);
				removeFile(tempRemoveFile);
			}}
			className="thumb-close"
		>
			<i className="fe fe-x" />
		</button>
	);

	const thumbs = files.map(([file, blob]: any, index: number) =>
		file.type.includes("image") ? (
			<div style={styles.thumb} key={index}>
				<div style={styles.thumb} key={index}>
					{addRemoveIcon(index)}
					<div style={styles.thumbInner}>
						<img
							src={blob}
							alt={intl.thumbs}
							style={styles.img}
						/>
					</div>
				</div>
			</div>
		) : (
			<></>
		)
	);

	return (
		<>
			<Popup
				trigger={() => (
					<InputLabel>
						{label ?? ""}
						{information && <div className="info-trigger">{"\u24d8"}</div>}
					</InputLabel>
				)}
				on={['click', 'hover']}
				position="right center"
				closeOnDocumentClick
			>
				{information && <div className="input-info">
					{information}
				</div>}
			</Popup>
			<div className="container text-center dropzoneStyle">
				<div
					{...getRootProps({
						className: "dropzone dropzone-single mb-3 dz-clickable",
					})}
				>
					<input {...getInputProps()} />
					{ loading ? 
						<div className="loading">{intl.resizingImage}</div> :
						<div className={`dz-message ${!done ? "" : "disabled"}`}>
							{isDragAccept && intl.acceptValue}
							{isDragReject && intl.rejectValue}
							{!isDragActive && intl.activeValue}
							<div className="dz-message-formats">
								{intl.allowedFormats}
							</div>
						</div>
					}
				</div>
			</div>
			<ul className="list-group mt-2">
				{files.length > 0 &&
					files.map(([file, blob]: any, index: number) => (
						<li	className="list-group-item list-group-item-success"	key={index}>
							{file.name}
							<div key={index}>{addRemoveIcon(index)}</div>
						</li>
					))}
			</ul>
			<aside style={styles.thumbsContainer}>{thumbs}</aside>
		</>
	);
};

export default Dropzone;
