import enMessage from "./locales/en.json";
import fiMessage from "./locales/fi.json";
import frMessage from "./locales/fr.json";
import eeMessage from "./locales/ee.json";
import esMessage from "./locales/es.json";
import itMessage from "./locales/it.json";
import nbMessage from "./locales/nb-NO.json";
import svMessage from "./locales/sv.json";
import ptMessage from "./locales/pt.json";
import plMessage from "./locales/pl.json";
import deMessage from "./locales/de.json";
import daMessage from "./locales/da.json";

export const localeLanguage = navigator.language.split(/[-_]/)[0];
export const messagesForLanguages = {
	en: enMessage,
	fi: fiMessage,
	fr: frMessage,
	ee: eeMessage,
	es: esMessage,
	it: itMessage,
	sv: svMessage,
	pt: ptMessage,
	pl: plMessage,
	de: deMessage,
	da: daMessage,
	// Make all three Norwegian variants point to Bokmål (not really correct)
	nb: nbMessage,
	no: nbMessage,
	nn: nbMessage,
};
