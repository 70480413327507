import "./styles/css/Theme.css";
import "./styles/fonts/feather/feather.min.css";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import App from "./App";

// pick a date util library
import ServiceWorkerWrapper from "./ServiceWorkerWrapper";

const rootElement = document.getElementById("root");
const baseUrl =
	document.getElementsByTagName("base")[0].getAttribute("href") || "";

ReactDOM.render(
	<BrowserRouter basename={baseUrl}>
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<ServiceWorkerWrapper />
			<App />
		</MuiPickersUtilsProvider>
	</BrowserRouter>,
	rootElement
);
