import "./Notification.css";

import React, { Component, ReactNode } from "react";

import { NotificationType } from "./NotificationType";

/**
 * The properties for the notification component
 */
interface IProps {
	type: NotificationType;
	timeout: number;
	title?: string | null;
	message: string;
	onRequestHide: () => void;
}

export default class Notification extends Component<IProps> {
	/** The active display timer */
	private timer?: number;

	public componentDidMount(): void {
		const { timeout: timeOut } = this.props;

		if (timeOut !== 0) {
			this.timer = window.setTimeout(this.requestHide, timeOut);
		}
	}

	public componentWillUnmount = () => {
		if (this.timer) {
			clearTimeout(this.timer);
			this.timer = undefined;
		}
	};

	/**
	 * Triggered when the notification is clicked and should be closed, the listener is responsible for closing the notification
	 */
	private requestHide = () => {
		const { onRequestHide } = this.props;
		if (onRequestHide) {
			onRequestHide();
		}
	};

	public render(): ReactNode {
		const { type, title, message } = this.props;
		return (
			<div
				className={`appear notification notification-animate-appear notification-${NotificationType[
					type
				].toLowerCase()}`}
				onClick={() => this.requestHide()}
			>
				{title && <div className="notification-title">{title}</div>}
				<div className="notification-message">{message}</div>
			</div>
		);
	}
}
