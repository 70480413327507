import * as React from "react";
import { useIntl } from "react-intl";

interface IProps {
    data: {
        country: string,
        address: string,
        address2: string,
        phone: string
    }[]
}

const Address = (props: IProps) => {
    const intl = useIntl();

    return (
        <div className="footer-addresses-wrapper">
            <div className="footer-header">{intl.formatMessage({
                id: "ClaimForm.FooterContact",
            })}
            </div>
            {props.data.map((address) => (
                <div className="footer-addresses" key={address.country}>
                    <div>{address.country}</div>
                    <div>{address.address}</div>
                    <div>{address.address2}</div>
                    <a href={"tel:"+address.phone} >{address.phone}</a>
                </div>
            ))}
        </div>
    )
}

export default Address;