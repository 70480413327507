import React, { memo } from "react";
import { Route } from "react-router";
import { ThemeProvider } from "styled-components";
import { LayoutRoute } from "./components/Layout";
import { TermsAndConditions } from "./components/TermsAndConditions/TermsAndConditions";
import logoBlack from "./Utils/img/omocom_logo_black.svg";
import logoColor from "./Utils/img/logo_color.svg";
import logoWhite from "./Utils/img/logo_white.svg";

const omocomTheme = {
	logoBlack,
	logoWhite,
	logoColor,
	btnPrimaryColor: "#CB7469",
	backgroundFallback: "#FFF",
};

const TermsAndConditionRouter = memo(({ match }: any) => (
	<div>
		<TermsAndConditions insuranceId={match.params.insuranceId} />
	</div>
));
TermsAndConditionRouter.displayName = "TermsAndConditionRouter";

const App = () => (
	<ThemeProvider theme={omocomTheme}>
		<Route
			exact
			path="/claimsform/:insuranceId"
			component={LayoutRoute}
		/>
		<Route exact path="/claimsform" component={LayoutRoute} />
		<Route
			exact
			path="/termsandconditions/:insuranceId"
			component={TermsAndConditionRouter}
		/>
	</ThemeProvider>
);

export default memo(App);
