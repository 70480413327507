import * as React from "react";
import Address from "./Address";
import { LogoWhite } from "../../../styles/styledComponents/styled";
import { useIntl } from "react-intl";
import '../../../styles/css/Footer.css';

const data = [
    {
        country: "Sweden",
        address: "Birkagatan 1",
        address2: "113 36 Stockholm",
        phone: "+46 8 520 278 70"
    }
];

const Footer = () => {
    const intl = useIntl();

    return (
        <div className="footer-wrapper">
            <div className="footer">
                <div className="footer-left">

                </div>
                <Address data={data} />
                <div className="footer-right">
                    <div className="footer-header">{intl.formatMessage({
                        id: "ClaimForm.FooterSocial",
                    })}
                    </div>
                    <div><a href="https://www.facebook.com/Omocom-102275991158674" >Facebook</a></div>
                    <div><a href="https://www.instagram.com/lifeatomocom" >Instagram</a></div>
                    <div><a href="https://www.linkedin.com/company/omocominsurance" >Linkedin</a></div>
                    <div><a href="https://www.omocom.insurance/blog" >Blog</a></div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="footer-bottom-content">
                    <div className="footer-bottom-content-inner">
                        {intl.formatMessage({
                            id: "ClaimForm.FooterInsuranceText",
                        })}
                    </div>
                </div>
                <div className="logo-wrapper">
                    <LogoWhite />
                </div>
            </div>
        </div>
    )
}

export default Footer;
