/**
 * Service providing configuration values for the application
 */
export default class ConfigurationService {
	/**
	 * Returns the maximum file size allowed per file.
	**/
	public static getClaimFormFileSizeLimit(): number {
		return 1 * 1024 * 1024;
	}

	/**
	 * Returns the maximum file size allowed for all files combined.
	**/
	public static getClaimsFormAllFilesSizeLimit(): number {
		return 9 * 1024 * 1024;
	}

	/**
	 * Returns the maximum number of files allowed.
	**/
	public static getClaimFormMaximumAmountOfFiles(): number {
		return 9;
	}
}
