import React, { useEffect } from "react";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import { useIntl } from "react-intl";
import { BootstrapInput } from "../../../styles/styledComponents/stylesForComponents";
import { countries } from "../../../models/IClaimInfo";
import { Claim, IFieldProps } from "../../../models/IClaimFormDefaults";

interface ICountryOfIncident {
    claim: Claim;
    onChange: Function;
    claimState: IFieldProps;
    label: String
    done: boolean;
}

const CountryOfIncident: React.FC<ICountryOfIncident> = (props: ICountryOfIncident) => {
    const { claim, onChange, claimState, label, done } = props;
    const intl = useIntl();

    useEffect(() => {
        if (claimState.marketName) {
            countries.forEach(country => {
                if (country.shortForm === claimState.marketName) {
                    onChange(country.name, "countryOfIncident")
                }
            });

        }
        // eslint-disable-next-line
    }, [claimState])

    return (
        <>
            <InputLabel>
                {label}
            </InputLabel>
            <Select
                labelId="countryOfIncident"
                id="countryOfIncident"
                value={claim.countryOfIncident || ""}
                onChange={(event) => onChange((event.currentTarget as any).dataset.value, "countryOfIncident")}
                input={<BootstrapInput />}
                disabled={done}
                fullWidth
            >
                {countries.map((c) => (
                    <MenuItem key={c.name} value={c.name}>
                        {intl.formatMessage({
                            id: c.intl,
                        })}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
};

export default CountryOfIncident;
