import React from "react";
import { Grid } from "@material-ui/core";
import { useIntl } from "react-intl";
import { FieldGroupString } from "../CommonComponents/FieldGroupString";
import { Claim, FieldType, IFieldProps } from "../../../models/IClaimFormDefaults";
import moment from "moment";

interface IInsuranceInputInformationProps {
	claim: Claim;
	setValue: Function;
	claimState: IFieldProps;
	pincode: string;
	setPincode: Function;
	showPincode: boolean;
	done: boolean;
	setError: Function
	displayErrors: boolean;
	insuranceIdError: boolean
}

const InsuranceInputInformation: React.FC<IInsuranceInputInformationProps> = (props) => {
	const { claim, setValue, pincode, claimState, setPincode, showPincode, done, setError, displayErrors, insuranceIdError } = props;
	const intl = useIntl();

	const search = new URLSearchParams(window.location.search);
	const insuranceIdParameter = search.get("insuranceId");
	const urlPincode = search.get("pincode");

	return (
		<>
			<Grid item md={6} xs={12}>
				<FieldGroupString
					value={claim.insuranceId || ""}
					onChange={setValue}
					done={done}
					multiLineTextBox
					id="insuranceId"
					fieldType={FieldType.InsuranceId}
					name="insuranceId"
					autocomplete="organization"
					information={intl.formatMessage({
						id: "ClaimForm.InformationTextPolicyNumber",
					})}
					label={intl.formatMessage({
						id: "ClaimForm.policyNumberLabel",
					})}
					placeholder={intl.formatMessage({
						id: "ClaimForm.policyNumberPlaceholder",
					})}
					disabled={(insuranceIdParameter !== null)}
					isRequired={false}
					setError={setError}
					displayErrors={displayErrors}
					insuranceIdError={insuranceIdError}
				/>
			</Grid>
			{showPincode && <Grid item md={2} xs={12}>
				<FieldGroupString
					value={pincode || ""}
					onChange={setPincode}
					done={done}
					multiLineTextBox
					id="pincode"
					fieldType={FieldType.Text}
					name="pincode"
					autocomplete="organization"
					label={intl.formatMessage({
						id: "ClaimForm.pincodeLabel",
					})}
					placeholder={intl.formatMessage({
						id: "ClaimForm.pincodePlaceholder",
					})}
					information={intl.formatMessage({
						id: "ClaimForm.InformationTextPincode",
					})}
					disabled={(urlPincode !== null)}
					isRequired={false}
					setError={setError}
					displayErrors={displayErrors}
				/>
			</Grid>
			}
			{claimState.platformId && claimState.insuranceEnd && <Grid item md={12} xs={12}>
				<div>{`${intl.formatMessage({id: "ClaimForm.InsuredObject"})} ${claimState.platformDisplayName || claimState.platformId}`}</div>
				<div>{`${intl.formatMessage({id: "ClaimForm.InsuranceValidUntil"})} ${moment(claimState.insuranceEnd).format("YYYY-MM-DD")}`}</div>
			</Grid>
			}
			{/* <FieldGroupString
				value={claim.platform || ""}
				onChange={setValue}
				done={done}
				multiLineTextBox
				id="platform"
				name="platform"
				fieldType={FieldType.Text}
				label={intl.formatMessage({
					id: "ClaimForm.marketLabel",
				})}
				isRequired={false}
				setError={setError}
				displayErrors={displayErrors}
			/> */}
		</>
	);
};

export default InsuranceInputInformation;
