import React from "react";
import { Grid, FormGroup } from "@material-ui/core";
import { useIntl } from "react-intl";
import { buttonObjectTypes } from "../../../models/IClaimFormDefaults";
import ButtonComponent from "../CommonComponents/ButtonComponent";

interface IInsuredObjectTypeSelection {
	setCategory: Function,
	done: boolean;
	displayErrors: boolean;
}

const InsuredObjectTypeSelection: React.FC<IInsuredObjectTypeSelection> = (props: IInsuredObjectTypeSelection) => {
	const intl = useIntl();
	const {setCategory, done, displayErrors} = props;

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={8}>
				<FormGroup id="formControlsCondition">
					<ButtonComponent
						onChange={(e: any) => setCategory(e)}
						done={done}
						fieldName="objectType"
						buttonTypes={buttonObjectTypes}
						label={intl.formatMessage({
							id: "ClaimForm.insuranceItemType",
						}) + ' *'}
					/>
					{displayErrors && (
						<span className="buttonStateError">
							{intl.formatMessage({
								id: "ClaimForm.validateFieldRequired",
							})}
						</span>
					)}
				</FormGroup>
			</Grid>
		</Grid>
	);
};

export default InsuredObjectTypeSelection;