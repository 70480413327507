import React from "react";
import Alert from "react-bootstrap/Alert";
import {
	TextField,
	OutlinedInputProps,
	InputLabel,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import {
	useStylesTextField,
	useStylesTextArea,
	helperTextStyles,
} from "../../../styles/styledComponents/stylesForComponents";
import { FormValidation } from "../../../validation/FormValidation";
import { FieldType } from "../../../models/IClaimFormDefaults";
import Popup from "reactjs-popup";

export type OnInputChange = (e: React.ChangeEvent<HTMLInputElement>) => void;
export type OnInputDateChange = (date: string, dateStr: string) => void;

interface IProps {
	/** The id of the form control */
	id: string;

	/** Label text for the control */
	label?: string;

	/** Help text to describe what the user should enter */
	help?: string;

	/** Information text that shows onclick of the (i) icon */
	information?: string;

	/** Name of the form field, if none is set the id is used instead */
	name?: string;

	/** Placeholder text for when the field is empty */
	placeholder?: string;

	autocomplete?: string;

	/** True if the field is required, false otherwise */
	isRequired: boolean;

	/** True if the field is disabled, false otherwise */
	disabled?: boolean;

	/** The type of field to use */
	fieldType: FieldType;

	/** Indicates if the field is multiLine text field */
	multiLineTextBox: boolean;

	/** Input value */
	value: string | number | undefined;

	/** Function that is called when the value is changed */
	onChange: Function;

	/** Indicates if the field belongs to a category object */
	category?: string;

	/** Boolean that checks if the form has been posted */
	done: boolean;

	/** Sets the error if there is one */
	setError?: Function;

	/** Indicates whether the errors should be displayed or not */
	displayErrors: boolean;

	/** Indicates whether there is an invalid insurance id */
	insuranceIdError?: boolean;
}

export const FieldGroupString: React.FC<IProps> = (props) => {
	let classes = useStylesTextField();
	const textAreaClass = useStylesTextArea();
	const helperTestClasses = helperTextStyles();
	const intl = useIntl();
	const {
		autocomplete,
		label,
		id,
		fieldType,
		name,
		disabled,
		isRequired,
		placeholder,
		multiLineTextBox,
		information,
		help,
		value,
		onChange,
		category,
		done,
		setError,
		displayErrors,
		insuranceIdError
	} = props;

	const type = (fieldType: FieldType) => {
		switch (fieldType) {
			case FieldType.Email:
				return "email"
			case FieldType.Number:
				return "number"
			default:
				return "text"
		}
	}

	const handleOnChange = (e: any) => {
		setError && setError(errors(e.currentTarget.value), id); 

		if(fieldType === FieldType.Number) {
			e = parseInt(e.currentTarget.value);
		};

		if(e.currentTarget) {
			e = e.currentTarget.value;
		}
		
		onChange(e, id, category);
	}

	if (!multiLineTextBox) {
		classes = textAreaClass;
	}

	const errors = (newValue?: string) => {
		let currentValue = newValue !== undefined ? newValue : value;
		switch (fieldType) {
			case FieldType.Email:
				if (currentValue?.toString().includes("@privaterelay.appleid.com")) {
					return intl.formatMessage({ id: "ClaimForm.validateFieldUnsupportedDomainEmail" });
				}
				return !FormValidation().isValidEmail(currentValue?.toString()) && 
				intl.formatMessage({ id: "ClaimForm.validateFieldEmail" });
			case FieldType.Number:
				return !FormValidation().isValidObjectPriceAmount(currentValue?.toString()) && 
				intl.formatMessage({ id: "ClaimForm.validateFieldCurrency" });
			case FieldType.InsuranceId:
				if (!currentValue ||
					currentValue === "00000000-0000-0000-0000-000000000000") {
					return false;
				}
				else if (!FormValidation().isValidGuid(currentValue?.toString()) || insuranceIdError) {
					return intl.formatMessage({ id: "ClaimForm.validateCurrentFieldInsuranceId" });
				}
				return;
			default:
				return isRequired && !FormValidation().isValidText(currentValue, id) && 
				intl.formatMessage({ id: "ClaimForm.showErrMessageForFieldText" });
		}
	}

	const helperText = () => {
		if (!multiLineTextBox && !displayErrors) {
			return (
				<span className="helperTextStyle">
					{intl.formatMessage({
						id: "ClaimForm.validateFieldLength",
					})}
				</span>
			);
		} else if (value) {
			return displayErrors ? (
				<span className="helperTextDisplayError">
					{errors()}
				</span>
			) : (
				<span />
			);
		} else if (!value && isRequired && displayErrors) {
			return (
				<span className="helperTextDisplayError">
					{intl.formatMessage({
						id: "ClaimForm.validateFieldRequired",
					})}
				</span>
			);
		}

		return <span className="helperTextDefaultStyle" />;
	};

	const showError = () => {
		if (value && displayErrors && errors()) {
			return true;
		}
		return false;
	};

	const maxLength = name === "objectDescription" || !multiLineTextBox	? 1000 : 200;

	return (
		<div id={id} className={displayErrors ? "was-validated" : ""}>
			<Popup
				trigger={() => (
					<InputLabel>
						{label ?? ""}
						{information && <div className="info-trigger">{"\u24d8"}</div>}
					</InputLabel>
				)}
				on={['click', 'hover']}
				position="right center"
				closeOnDocumentClick
			>
				{information && <div className="input-info">
					{information}
				</div>}
			</Popup>
			<TextField
				multiline={!multiLineTextBox}
				inputProps={{"data-category": category, maxLength }}
				minRows={5}
				maxRows={5}
				error={showError()}
				helperText={helperText()}
				type={type(fieldType)}
				name={name || id}
				autoComplete={autocomplete || "off"}
				disabled={disabled || done}
				onChange={handleOnChange}
				required={isRequired}
				placeholder={placeholder || ""}
				value={value === null ? "" : value}
				fullWidth
				variant="outlined"
				FormHelperTextProps={{
					classes: helperTestClasses,
				}}
				className={helperTestClasses.contained}
				InputProps={
					{
						classes
					} as Partial<OutlinedInputProps>
				}
			/>
			{help && <Alert variant="warning">{help}</Alert>}
		</div>
	);
};
