import * as React from "react";
import { withRouter } from "react-router-dom";
import ClaimFormContainer from "./ClaimFormContainer";
import { ThemedBackground } from "../styles/styledComponents/styled";
import { ThemeProvider } from "@material-ui/core/styles";
import { Theme } from "../styles/styledComponents/styled";

const Layout = (props: any) => {
	return (
		<ThemedBackground>
			<ThemeProvider theme={Theme}>
				<ClaimFormContainer insuranceId={props.match.params.insuranceId} />
			</ThemeProvider>
		</ThemedBackground>
	);
};

export const LayoutRoute = withRouter(Layout);
