export interface IClaimInfo {
	issueId: string;
	issueKey: string;
	sharinId: string; // A guid, really
}

export const countries = [	
	{ name: "Austria", shortForm: "AT", intl: "ClaimForm.CountriesListAustria" },
	{ name: "Denmark", shortForm: "DK", intl: "ClaimForm.CountriesListDenmark" },
	{ name: "Estonia", shortForm: "ET", intl: "ClaimForm.CountriesListEstonia" },
	{ name: "Estonia", shortForm: "ET", intl: "ClaimForm.CountriesListEstonia" },
	{ name: "Finland", shortForm: "FI", intl: "ClaimForm.CountriesListFinland" },
	{ name: "France", shortForm: "FR", intl: "ClaimForm.CountriesListFrance" },
	{ name: "Germany", shortForm: "DE", intl: "ClaimForm.CountriesListGermany" },
	{ name: "Italy", shortForm: "IT", intl: "ClaimForm.CountriesListItaly" },
	{ name: "Norway", shortForm: "NO", intl: "ClaimForm.CountriesListNorway" },
	{ name: "Poland", shortForm: "PL", intl: "ClaimForm.CountriesListPoland" },
	{ name: "Portugal", shortForm: "PT", intl: "ClaimForm.CountriesListPortugal" },
	{ name: "Spain", shortForm: "ES", intl: "ClaimForm.CountriesListSpain" },
	{ name: "Sweden", shortForm: "SE", intl: "ClaimForm.CountriesListSweden" }
]

export const languages = [
	{ name: "English", shortForm: "en", label: "English" },
	{ name: "Danish", shortForm: "da", label: "Dansk" },
	{ name: "Estonian", shortForm: "ee", label: "Eesti keel" }, 
	{ name: "Finnish", shortForm: "fi", label: "Suomeksi" },
	{ name: "French", shortForm: "fr", label: "Français" },
	{ name: "German", shortForm: "de", label: "Deutsch" },
	{ name: "Italian", shortForm: "it", label: "Italiano" },
	{ name: "Norwegian", shortForm: "no", label: "Norsk" },
	{ name: "Polish", shortForm: "pl", label: "Polski" },
	{ name: "Portuguese", shortForm: "pt", label: "Português" },
	{ name: "Spanish", shortForm: "es", label: "Español" },
	{ name: "Swedish", shortForm: "sv", label: "Svenska" }
]

