import "./NotificationContainer.css";
import React, { useState, useEffect } from "react";
import Notification from "./Notification";
import { NotificationMessage } from "./NotificationMessage";
import NotificationsManager from "./NotificationsManager";

/**
 * The notification container for the NotificationsManager, a view may contain multiple containers but they will all display the same
 * notifications.
 */
const NotificationContainer: React.FC = () => {
	const [notifications, setNotifications] = useState(
		[] as NotificationMessage[]
	);

	useEffect(() => {
		NotificationsManager.addChangeListener(handleNotificationsChange);
		return () => {
			NotificationsManager.removeChangeListener(
				handleNotificationsChange
			);
		};
	});

	/**
	 * Handler for when the notifications array changes in the manager
	 */
	const handleNotificationsChange = (notification: NotificationMessage[]) => {
		setNotifications(notification);
	};

	/**
	 * Handles the closing of a notifications message
	 */
	const handleRequestHide = (notification: NotificationMessage) => {
		NotificationsManager.remove(notification);
	};

	return (
		<div
			className={`notification-container${
				notifications.length === 0
					? " notification-container-empty"
					: ""
			}`}
		>
			{notifications.map((notification) => (
				<Notification
					key={notification.id}
					type={notification.type}
					title={notification.title}
					message={notification.message}
					timeout={notification.timeout}
					onRequestHide={() => handleRequestHide(notification)}
				/>
			))}
		</div>
	);
};

export default NotificationContainer;
