import { Guid } from "guid-typescript";
import { IntlShape } from "react-intl";
import moment from "moment";
import { IncidentType, MainCategory } from "../api/ExternalApi";
import NotificationsManager from "../components/ErrorHandling/Notifications/NotificationsManager";
import { Claim } from "../models/IClaimFormDefaults";

/**
 * A set of validation methods that can be used to validate different types of values.
 */
const FormValidation = () => {
	return {
		/**
		 * Validates that the given value is a valid email address
		 *
		 * @param email The email address to validate
		 * @returns True if the given email is a valid address, false otherwise
		 */
		isValidEmail: (email: string | undefined): boolean => {
			if (!email) {
				return false;
			}
			const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return emailRex.test(email);
		},

		/**
		 * Validates that the given value is a valid currency string
		 *
		 * @param value The value to validate
		 * @returns True if the value is a valid currency value, false otherwise
		 * ToDo: Lock down the list to explicit, well known currencies according to ISO standard
		 */
		isValidObjectPriceAmount: (value: string | undefined): boolean => {
			if (!value) {
				return false;
			}
			const currencyRex = /^[0-9][0-9]*$/;
			return currencyRex.test(value);
		},

		isValidGuid: (value: string | undefined): boolean => {
			if (!value || value === "00000000-0000-0000-0000-000000000000") {
				return false;
			}
			
			const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
			return regex.test(value) && Guid.isGuid(value);
		},

		isValidText: (value: string | number | undefined, id: string): boolean => {
			if (!value) {
				return false;
			}
			else if (typeof(value) === 'number' || id === "firstName" || id === "lastName") {
				return true;
			}
			
			const minLength = value?.length > 2;
			return minLength;
		},
	};
};

/**
 * Validates Item fields
 */
const ValidateItem = (claim: Claim, intl?: IntlShape) => {
	if (!claim.item?.objectDescription || !claim.item?.placeOfPurchase || !claim.item?.objectPriceAmount) {
		intl && NotificationsManager.error(
			intl.formatMessage({
				id: "ClaimForm.IsRequiredField",
			})
		);
		return false;
	} else if (!claim.item?.dateOfPurchase || !moment(claim.item.dateOfPurchase).isValid() || claim.item.objectNewAtPurchase === undefined) {

		intl && NotificationsManager.error(
			intl.formatMessage({
				id: "ClaimForm.IsRequiredField",
			})
		);
		return false;
	} else if (claim.dateOfDamage && !claim.dateOfDamage.isSameOrAfter(claim.item?.dateOfPurchase, 'day')) {
		intl && NotificationsManager.error(
			intl.formatMessage({
				id: "ClaimForm.validateFieldDate",
			})
		);
		return false
	}
	return true;
}

/**
 * Validates individual category
 */
const ValidateCategory = (claim: Claim, category: MainCategory, savedItems: any, intl?: IntlShape): boolean => {
	if (claim.item?.objectDescription ||
		claim.item?.dateOfPurchase ||
		claim.item?.placeOfPurchase ||
		claim.item?.objectPriceAmount) {
		if (!ValidateItem(claim, intl)) {
			return false;
		}
	}
	
	switch (category) {
		case MainCategory.Item:
			if (!claim.item?.objectDescription &&
				!claim.item?.dateOfPurchase &&
				!claim.item?.placeOfPurchase &&
				!claim.item?.objectPriceAmount) {
				if (!savedItems.length) {
					intl && NotificationsManager.error(
						intl.formatMessage({
							id: "ClaimForm.IsRequiredField",
						})
					);
					return false;
				}
			}
			break;
		case MainCategory.Vehicle:
			if (!claim.vehicle?.licensePlate) {
				intl && NotificationsManager.error(
					intl.formatMessage({
						id: "ClaimForm.IsRequiredField",
					})
				);
				return false;
			}
			else if (claim.vehicle.hasOwnerInsuranceCompany &&
				!claim.vehicle.ownerInsuranceCompany) {
				intl && NotificationsManager.error(
					intl.formatMessage({
						id: "ClaimForm.IsRequiredField",
					})
				);
				return false;
			}
			break;
		case MainCategory.Accommodation:
			if (!claim.accommodation?.street ||
				!claim.accommodation.city ||
				!claim.accommodation.country) {
				intl && NotificationsManager.error(
					intl.formatMessage({
						id: "ClaimForm.IsRequiredField",
					})
				);
				return false;
			}
			else if (claim.accommodation.hasOwnerInsuranceCompany &&
				!claim.accommodation.ownerInsuranceCompany) {
				intl && NotificationsManager.error(
					intl.formatMessage({
						id: "ClaimForm.IsRequiredField",
					})
				);
				return false;
			}
			break;
		default:
			intl && NotificationsManager.error(
				intl.formatMessage({
					id: "ClaimForm.IsRequiredField",
				})
			);
			return false;
	}

	return true;
}

/**
 * Validates INPUT types
 */
const ValidateTextFields = (claim: Claim): boolean => {
	if (!claim.firstName ||
		!claim.lastName ||
		!claim.emailAddress ||
		!claim.descriptionOfEvents) {
		return false;
	}
	
	return true;
};

/**
 * Validates a BUTTON type
 */
const ValidateButtons = (claim: Claim): boolean => {
	if (claim.incidentType === undefined || (claim.incidentType === IncidentType.Theft && claim.policeReportFiled === undefined)) {
		return false;
	}
	return true;
};

/**
 * Validates a DATE type
 * if any error, returns false, else true
 */
const ValidateDates = (dateOfDamage: moment.Moment | undefined): boolean => {
	if (dateOfDamage && moment(dateOfDamage).isValid()) {
		return true;
	}

	return false;
};

const checkIfFormValidates = (claim: Claim, category: MainCategory, savedItems: any, intl: IntlShape, error: {[key: string]: string}, insuranceIdError: boolean) => {
	if (Object.keys(error).length) {
		NotificationsManager.error(Object.values(error)[0]);
		return false;
	} else if (!ValidateCategory(claim, category, savedItems, intl)) {
		return false;
	} else if (!ValidateTextFields(claim) || !ValidateButtons(claim) || !ValidateDates(claim.dateOfDamage)) {
		NotificationsManager.error(
			intl.formatMessage({
				id: "ClaimForm.IsRequiredField",
			})
		);
		return false;
	} else if (claim.insuranceId) {
		if (!FormValidation().isValidGuid(claim.insuranceId) || insuranceIdError) {
			if (claim.insuranceId !== "00000000-0000-0000-0000-000000000000") {
				NotificationsManager.error(
					intl.formatMessage({
						id: "ClaimForm.validateFieldInsuranceId",
					})
				);
				return false;
			}
		}
	}

	return true;
}

export {
	FormValidation,
	ValidateItem,
	ValidateCategory,
	ValidateTextFields,
	ValidateButtons,
	ValidateDates,
	checkIfFormValidates,
};
