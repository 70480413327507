import React from "react";
import { Grid } from "@material-ui/core";
import { useIntl } from "react-intl";
import { FieldGroupString } from "../CommonComponents/FieldGroupString";
import { Claim, FieldType } from "../../../models/IClaimFormDefaults";

interface IUserInputInformationProps {
	claim: Claim;
	setValue: Function;
	done: boolean;
	setError: Function;
	displayErrors: boolean;
}

const UserInputInformation: React.FC<IUserInputInformationProps> = (
	props: IUserInputInformationProps
) => {
	const { claim, setValue, done, setError, displayErrors } = props;
	const intl = useIntl();

	const search = new URLSearchParams(window.location.search);
	const emailParameter = search.get("email");
	const firstNameParameter = search.get("firstname");
	const lastNameParameter = search.get("lastname");
	const phoneParameter = search.get("phone");

	return (
		<Grid container spacing={3}>
			<Grid item md={7} xs={12}>
				<FieldGroupString
					value={claim.ssn || ""}
					onChange={setValue}
					done={done}
					multiLineTextBox
					id="ssn"
					fieldType={FieldType.Text}
					name="ssn"
					autocomplete="organization"
					label={intl.formatMessage({
						id: "ClaimForm.personalNumberLabel",
					})}
					placeholder={intl.formatMessage({
						id: "ClaimForm.personalNumberPlaceholder",
					})}
					isRequired={false}
					setError={setError}
					displayErrors={displayErrors}
				/>
				<FieldGroupString
					value={firstNameParameter || claim.firstName || ""}
					onChange={setValue}
					done={done}
					multiLineTextBox
					id="firstName"
					fieldType={FieldType.Text}
					name="firstName"
					autocomplete="given-name"
					label={intl.formatMessage({
						id: "ClaimForm.firstNameLabel",
					}) + ' *'}
					placeholder={intl.formatMessage({
						id: "ClaimForm.firstNameLabel",
					})}
					isRequired
					setError={setError}
					displayErrors={displayErrors}
				/>
				<FieldGroupString
					value={lastNameParameter || claim.lastName || ""}
					onChange={setValue}
					done={done}
					multiLineTextBox
					id="lastName"
					fieldType={FieldType.Text}
					autocomplete="family-name"
					name="lastName"
					label={intl.formatMessage({
						id: "ClaimForm.lastNameLabel",
					})  + ' *'}
					placeholder={intl.formatMessage({
						id: "ClaimForm.lastNameLabel",
					})}
					isRequired
					setError={setError}
					displayErrors={displayErrors}
				/>
				<FieldGroupString
					value={emailParameter || claim.emailAddress || ""}
					onChange={setValue}
					done={done}
					multiLineTextBox
					id="emailAddress"
					fieldType={FieldType.Email}
					autocomplete="email"
					name="emailAddress"
					label={intl.formatMessage({
						id: "ClaimForm.emailAddressLabel",
					}) + ' *'}
					placeholder={"XXXX@XXXXXXXXX.XX"}
					isRequired
					information={intl.formatMessage({
						id: "ClaimForm.InformationTextEmail",
					})}					
					setError={setError}
					displayErrors={displayErrors}
				/>
				<FieldGroupString
					value={phoneParameter || claim.phoneNumber || ""}
					onChange={setValue}
					done={done}
					multiLineTextBox
					id="phoneNumber"
					name="phoneNumber"
					autocomplete="tel"
					fieldType={FieldType.Phone}
					label={intl.formatMessage({
						id: "ClaimForm.phoneNumberLabel",
					})}
					isRequired={false}
					placeholder={"123-456-78-90"}
					setError={setError}
					displayErrors={displayErrors}
				/>
			</Grid>
		</Grid>
	);
};

export default UserInputInformation;
