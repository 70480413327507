import React, { memo } from "react";
import Modal from "react-bootstrap4-modal";
import { LogoBig } from "../../styles/styledComponents/styled";

interface IProps {
	onCloseModal: () => void;
	header: string;
	show: boolean;
	intl: any;
	text?: string;
	textIssueKey?: string;
	email?: string;
	done: boolean;
	logoResponse?: any;
}

const claimModal = ({
	show,
	onCloseModal,
	header,
	text,
	textIssueKey,
	email,
	done,
	logoResponse,
	intl
}: IProps) => (
	<Modal
		dialogClassName="modal-dialog modal-dialog-vertical"
		visible={show}
		className="fixed-right"
		onClickBackdrop={!done ? onCloseModal : null}
	>
		<div className="modal-logo">{logoResponse || <LogoBig />}</div>
		<div className="modal-header">
			<h3>{header}</h3>
		</div>
		<div className="modal-body" style={{ wordBreak: "break-word" }}>
			{text ? <p>{text}</p> :
				<>
					{textIssueKey && <p>
						{intl.formatMessage({
							id: "ClaimForm.ModalTextCaseNumber",
						})}: <span className="focused">{textIssueKey}</span>
					</p>}
					<p>
						{email && intl.formatMessage({
							id: "ClaimForm.ModalResponseBodyEmail",
						})}: <span className="focused">{email}</span>
					</p>
					<p>
						{intl.formatMessage({
							id: "ClaimForm.ModalResponseBodyClaimReceived",
						})}
					</p>
				</>
			}
		</div>
	</Modal>
);

export default memo(claimModal);
