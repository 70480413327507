import styled from "styled-components";
import { createTheme } from "@material-ui/core/styles";

export const ThemedBackground = styled.div`
	background: ${(props) => props.theme.backgroundFallback};
	background: ${(props) => props.theme.backgroundGradient};
`;

export const PrimaryButton = styled.input`
	color: #fff;
	border-color: ${(props) => props.theme.btnPrimaryColor};
	background-color: ${(props) => props.theme.btnPrimaryColor};
`;

export const Logo = styled.div`
	background-image: url(${(props) => props.theme.logoBlack});
	width: auto;
	height: 2rem;
	background-position: left;
	background-repeat: no-repeat;
	margin: 2rem auto;

	@media only screen and (max-width : 1040px) {
		height: 1.5rem;
		background-position: center;
	}
`;

export const LogoWhite = styled.div`
	background-image: url(${(props) => props.theme.logoWhite});
	width: auto;
	height: 2.5rem;
	background-position: right;
	background-repeat: no-repeat;

	@media (max-width: 1040px) {
		height: 2rem;
	}
`;

export const LogoBig = styled.div`
	background-image: url(${(props) => props.theme.logoBlack});
	width: auto;
	height: 6rem;
	background-position: center;
	background-repeat: no-repeat;
`;

export const VersionStyle = styled.div`
	font-size: 0.8125rem;
	margin-left: 0.3rem;
`;

export const Theme = createTheme({
	overrides: {
		MuiInputBase: {
			root: {
				fontFamily: '"DM Sans", sans-serif',
				fontSize: '1.25rem',
				color: '#64676A',
				"&:hover": {
					outlineColor: "#30A2F3",
					backgroundColor: "#F8F9FB",
				},
				"& .MuiInputBase-input:focus": {
					background: '#FFFFFF',
					color: "#64676A",
					border: "1px solid #DADEE0",
					padding: '15.5px 14px'
				}
			}
		},
		MuiFormLabel: {
			root: {
				fontSize: '0.75rem',
			}
        },
		MuiButton: {
			root: {
				textTransform: "capitalize",
				fontSize: "1rem",
				fontFamily: "inherit",
			},
		},
	},
});
